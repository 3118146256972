import React from "react";
import "./action-button.css";
import { FaEdit, FaDownload, FaUpload, FaClipboardList, FaTrash, FaRedo, FaPlusCircle } from "react-icons/fa"; 

interface ActionsButtonProps {
    label: string;
    type: string;
    onActionClick: () => void;
    disabled?: boolean;
}

const FaEditIcon = FaEdit as unknown as React.FC;
const FaDownloadIcon = FaDownload as unknown as React.FC;
const FaUploadIcon = FaUpload as unknown as React.FC;
const FaClipboardListIcon = FaClipboardList as unknown as React.FC;
const FaTrashIcon = FaTrash as unknown as React.FC;
const FaRedoIcon = FaRedo as unknown as React.FC;
const FaAddIcon = FaPlusCircle as unknown as React.FC;

function ActionsButton({label, type, onActionClick, disabled}: ActionsButtonProps) {

    return (<button onClick={onActionClick} className="action-button" disabled={disabled}>
        {type === "edit" && <FaEditIcon />}
        {type === "download" && <FaDownloadIcon />}
        {type === "upload" && <FaUploadIcon />}
        {type === "list" && <FaClipboardListIcon />}
        {type === "trash" && <FaTrashIcon />}
        {type === "refresh" && <FaRedoIcon />}
        {type === "add" && <FaAddIcon />}
        {label}
    </button>);
};

export default ActionsButton;