import React, { useEffect, useState } from "react";
import usePost from "../../common/hooks/usePost";
import CommonTable from "../../common/common-table/common-table";
import { FaInfoCircle } from "react-icons/fa";
import { columns } from "./types";
import useGet from "../../common/hooks/useGet";

const DATA_ACQUISITION_STATUS_API = "data-acquisition/status";

const TableStatusOverview = ({openCSVFileUploadModal, onTableRowSelection}) => {

    const [acquisitionStatusList, acquisitionStatusError, isAcquisitionStatusLoading, makeAcquisitionStatusPostCall, resetAcquisitionStatusResult] = useGet(DATA_ACQUISITION_STATUS_API);
    
    useEffect(()=>{
        makeAcquisitionStatusPostCall();
    }, [makeAcquisitionStatusPostCall]);

    const handleRefreshStatus = () => {
        makeAcquisitionStatusPostCall();
    }

    const handleLinkCellClick = (rowData) => {
        console.log("Cell clicked:", rowData);
        if(rowData?.table_name){
            onTableRowSelection(rowData?.table_name);
        } else {
            console.error("table_name missing in selected row", rowData)
        }
    }

    return (
        <>
            {/* Error Alert */}
            {acquisitionStatusError && 
                <div className="alert alert-danger d-flex align-items-center p-1 col-6" role="alert">
                    <FaInfoCircle />
                    <div>
                        {acquisitionStatusError}
                    </div>
                </div>
            }
            {isAcquisitionStatusLoading && 
                <div className="d-flex align-items-center">
                    <p className="m-0 pe-1">Getting the table's download status ...</p>
                    <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                </div>
            }
                
            <CommonTable 
                data={acquisitionStatusList || []}
                columns={[...columns]}
                pageSize={15}
                enablePagination
                enableGlobalFilter
                enableCSVFileUpload
                enableRefresh
                onFileUploadClick={openCSVFileUploadModal}
                onLinkCellClick={handleLinkCellClick}
                onRefreshClick={handleRefreshStatus}
            />
        </>
    );

}

export default TableStatusOverview;