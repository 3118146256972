import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { certified, copyright_text, header } from "../utils/constants";
import { AuthData } from "../App";
import Profile from "./profile/Profile";
import MenuBar from "./menu-bar";
import { isTokenValid } from "../common/helpers/isTokenValid";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation()
  const logo = require("./../assests/logo.png");
  const { user } = AuthData();
  const [isAuthenticated, setAuthentication] = useState(false);

  useEffect(()=>{
    console.log('header.js : location updated:', location);
    if(isTokenValid()){
      console.log("valid")
      setAuthentication(true)
    }else {
      console.log("invlid")
      setAuthentication(false);
    }
  }, [location])

  return (
    <div className="page-container">
      <div className="content-wrap">
        <div className="header">
          <div className="header-left">
            <img
              alt="VEROSAI-icon"
              src={logo}
              onClick={() => navigate("/home")}
            />
            <div>{header}</div>
          </div>
          <div className="header-right">
            {/* <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <div style={{ marginRight: "10px" }}>{certified}</div>
              <img
                alt="SAP-Icon"
                src="https://sainav.com/wp-content/uploads/2024/01/csm_sap-partner-logo_4e3c3d048f.png"
              />
            </div> */}
            {isAuthenticated && <Profile />}
          </div>
        </div>
        {isAuthenticated && <MenuBar />}
        <Outlet />
      </div>
      <div className="footer">
        <p className="my-3">{copyright_text}</p>
      </div>
    </div>
  );
}
