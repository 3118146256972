import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { API_URL } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../../common/helpers/getAccessToken";

export default function PasswordReset() {
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const { uidb, token } = useParams();
  const Schema = Yup.object().shape({
    new: Yup.string().required("Required"),
    // new_confirm: Yup.string()
    //   .required("Required")
    //   .oneOf([Yup.ref("new"), null], "Passwords must match"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          new: "",
          // new_confirm: "",
        }}
        validationSchema={Schema}
        onSubmit={async(values) => {

          const authToken = getAccessToken();
          const bearerToken = `Bearer ${authToken}`;
          console.log(values);
          console.log(uidb, token);


          fetch(API_URL + "accounts/password_reset/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
            })
            .catch(function (res) {
              console.log(res);
              setErr("Error Occurred");
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>New Password :</label>
            <Field name="new" type={showPwd ? "text" : "password"} />
            {errors.new && touched.new ? (
              <div className="error">{errors.new}</div>
            ) : null}
            <input
              type="checkbox"
              className="pwdToggle"
              onChange={() => setShowPwd(!showPwd)}
              checked={showPwd}
            />
            Show Password
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Changed successfully!!</p>
            )}
            <div>
              <button className="primary-button" type="submit" style={{ fontWeight: "bolder" }}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
