import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";
import { RoutingType } from "../routing/types";


const columnHelper = createColumnHelper<CharacteristicType>();

export type CharacteristicType = {
    
}
export const columns: ColumnDef<CharacteristicType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "CharcInternalID",
        header: "Charc Internal ID",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Characteristic",
        header: "Characteristic",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcStatus",
        header: "Charc Status",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcStatusName",
        header: "Charc Status Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcDataType",
        header: "Charc Data Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcLength",
        header: "Charc Length",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcDecimals",
        header: "Charc Decimals",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcTemplate",
        header: "Charc Template",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValueIsCaseSensitive",
        header: "Value Is Case Sensitive",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcGroup",
        header: "Charc Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcGroupName",
        header: "Charc Group Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "EntryIsRequired",
        header: "Entry Is Required",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "MultipleValuesAreAllowed",
        header: "Multiple Values Are Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcValueUnit",
        header: "Charc Value Unit",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "UnitOfMeasureISOCode",
        header: "Unit Of Measure ISO Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "Currency",
        header: "Currency",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CurrencyISOCode",
        header: "Currency ISO Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcExponentValue",
        header: "Charc Exponent Value",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValueIntervalIsAllowed",
        header: "Value Interval Is Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValueIntervalIsAllowed",
        header: "Value Interval Is Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "NegativeValueIsAllowed",
        header: "Negative Value Is Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValidityStartDate",
        header: "Validity Start Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValidityEndDate",
        header: "Validity End Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ChangeNumber",
        header: "Change Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocumentType",
        header: "Document Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocNumber",
        header: "Doc Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocumentVersion",
        header: "Document Version",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocumentPart",
        header: "Document Part",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcMaintAuthGrp",
        header: "Charc Maint Auth Grp",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CharcIsReadOnly",
        header: "Charc Is Read Only",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcIsHidden",
        header: "Charc Is Hidden",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcIsRestrictable",
        header: "Charc Is Restrictable",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcExponentFormat",
        header: "Charc Exponent Format",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcEntryIsNotFormatCtrld",
        header: "Charc Entry Is Not Format Ctrld",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CharcTemplateIsDisplayed",
        header: "Charc Template Is Displayed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CreationDate",
        header: "Creation Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "LastChangeDate",
        header: "Last Change Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }
];