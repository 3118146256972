import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "./overlay/Overlay";
import { API_URL } from "../utils/constants";
import { Call } from "../utils/api";

export default function VendorProduction() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const Schema = Yup.object().shape({
    Id: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          Id: "",
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          // console.log(values);
          setLoading(true);
          try {
            let data = await Call(
              "product_view/",
              "POST",
              JSON.stringify(values)
            );
            setLoading(false);
            navigate("/product/view", {
              state: ["vendor", data],
            });
          } catch (res) {
            console.log(res);
            setErr("Error occurred");
            setLoading(false);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Vendor Production ID :</label>
            <Field name="Id" />
            {errors.Id && touched.Id ? (
              <div className="error">{errors.Id}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            <button className="primary-button" type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
