import { MouseEvent } from 'react';
import { Column, Row, Table, RowData } from "@tanstack/react-table";
import { FaEdit, FaCheck, FaRegWindowClose  } from "react-icons/fa";

const FaEditIcon = FaEdit as unknown as React.FC;
const FaCheckIcon = FaCheck as unknown as React.FC;
const FaRegWindowCloseIcon = FaRegWindowClose as unknown as React.FC;

interface EditCellProps<TData> {
    row: Row<TData>;
    table: Table<TData>;
}

const EditCell = <TData, >({ row, table }: EditCellProps<TData>) => {
  const meta = table.options.meta;

  const setEditedRows = (e: MouseEvent<HTMLButtonElement>) => {
    const elName = e.currentTarget.name;
    
    console.log("edit button clicked!")
    meta?.setEditedRows((old: any) => ({
      ...old,
      [row.id]: !old[row.id],
    }));

    if (elName !== 'edit') {
      meta?.revertData(row.index, e.currentTarget.name === 'cancel');
    }
  };

  return (
    <div className="edit-cell-container">
      {meta?.editedRows[row.id] ? (
        <>
          <button onClick={setEditedRows} name="cancel">
            <FaRegWindowCloseIcon {...({ className: "action-icon", style: { fill: "red" } } as React.ComponentProps<"svg">)} />
          </button>{' '}
          <button onClick={setEditedRows} name="done">
            <FaCheckIcon {...({ className: "action-icon", style: { fill: "green" } } as React.ComponentProps<"svg">)} />
          </button>
        </>
      ) : (
        <button onClick={setEditedRows} name="edit">
          <FaEditIcon {...({ className: "action-icon" } as React.ComponentProps<"svg">)} />
        </button>
      )}
    </div>
  );
};

export default EditCell;
