import React, { useState } from "react";
import "./login.css";
import { API_URL } from "../../utils/constants";
import { getAccessToken } from "../../common/helpers/getAccessToken";

export default function Verify() {
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPwd, setShowPwd] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let uname = e.target[4].value;
    let userName = e.target[3].value;
    let pwd = e.target[5].value;
    let role = e.target[0].value;
    let fname = e.target[1].value;
    let lname = e.target[2].value;
    // console.log(uname, pwd, role);
    if (!uname || !pwd || !role || !fname || !lname || !userName) {
      setErr("Please provide all values");
      return;
    }
    try {
      const token = await getAccessToken();
      const bearerToken = `Bearer ${token}`;
      let resp = await fetch(API_URL + "signup/", {
        method: "POST",
        body: JSON.stringify({
          email: uname,
          password: pwd,
          first_name: fname,
          last_name: lname,
          role,
          username: userName,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      });
      resp = await resp.json();
      if (resp.error) {
        setErr(resp.error);
        return;
      }
      setSuccess(true);
      setErr(false);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setErr(err.message);
    }
    // console.log(e);
    document.getElementById("myForm").reset();
  };
  return (
    <div className="main">
      {/* <h3>{"Sign Up"}</h3> */}
      <form onSubmit={handleSubmit} id="myForm">
        <label htmlFor="role">Role :</label>
        <select>
          <option value="">select a value ....</option>
          <option value="admin">Admin</option>
          <option value="customer">Customer</option>
        </select>
        <label htmlFor="fname">First Name :</label>
        <input
          type="text"
          id="fname"
          name="fname"
          autoComplete="off"
          placeholder="Enter your first name"
        />
        <label htmlFor="lname">Last Name :</label>
        <input
          type="text"
          id="lname"
          name="lname"
          autoComplete="off"
          placeholder="Enter your last name"
        />
        <label htmlFor="Username">User Name :</label>
        <input
          type="text"
          id="Username"
          name="Username"
          autoComplete="off"
          placeholder="Enter your User name"
        />
        <label htmlFor="first">Email :</label>
        <input
          type="text"
          id="first"
          name="first"
          autoComplete="off"
          placeholder="Enter your email"
        />
        <label htmlFor="password">Password :</label>
        <input
          type={showPwd ? "text" : "password"}
          id="password"
          name="password"
          placeholder="Enter your Password"
        />
        <input
          type="checkbox"
          className="pwdToggle"
          onChange={() => setShowPwd(!showPwd)}
          checked={showPwd}
        />
        Show Password
        {err && <p className="error">{err}</p>}
        {success && <p style={{ color: "lightgreen" }}>Added successfully!!</p>}
        <div className="wrap">
          <button className="primary-button" type="submit">Add User</button>
        </div>
      </form>
    </div>
  );
}
