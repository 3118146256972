import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/constants";
import { getAccessToken } from "../../common/helpers/getAccessToken";

export default function TenantEdit() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const { state } = useLocation();
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    tenant_id: Yup.string().required("Required"),
    tenant_name: Yup.string().required("Required"),
    status: Yup.boolean().required("Required"),
  });



  return (
    <div>
      <Formik
        initialValues={{
          tenant_id: state.tenant_id,
          tenant_name: state.tenant_name,
          status: state.status,
        }}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;

          fetch(API_URL + "admin1_update/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
              setSubmitting(false);
            })
            .catch(function (res) {
              console.log(res);
              setSubmitting(false);
              setErr("Error Occurred");
            });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="main">
            <label> Active Tenant :</label>
            <Field as="select" name="status">
              <option value="true">True</option>
              <option value="false">False</option>
            </Field>
            {errors.status && touched.status ? (
              <div className="error">{errors.status}</div>
            ) : null}
            <label>Tenant Id :</label>
            <Field name="tenant_id" disabled />
            {errors.tenant_id && touched.tenant_id ? (
              <div className="error">{errors.tenant_id}</div>
            ) : null}
            <label> Tenant Name :</label>
            <Field name="tenant_name" />
            {errors.tenant_name && touched.tenant_name ? (
              <div className="error">{errors.tenant_name}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <div style={{ display: "flex" }}>
              <button className="primary-button" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting...." : "Submit"}
              </button>
              <button
                className="ml-20"
                onClick={() => navigate("/tenant-view")}
              >
                Go back
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
