import { useState, useCallback } from "react";
import { getAccessToken, logout } from "../helpers/getAccessToken";
import axios, { AxiosRequestConfig } from "axios";

interface PostOptions<T> {
  body?: T;
  queryParams?: Record<string, string | number | boolean>;
  headers?: Record<string, string>;
  config?: AxiosRequestConfig;
}

const usePost = <T, R>(url: string) => {
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = "https://verosdata.com/api/";

  const makePostCall = useCallback(
    async ({ body, queryParams, headers, config }: PostOptions<T> = {}) => {
      setIsLoading(true);
      setError(null);

       const token = await getAccessToken();
      const bearerToken = `Bearer ${token}`;

      try {
        const queryString = queryParams
          ? `?${new URLSearchParams(queryParams as Record<string, string>).toString()}`
          : "";

        const response = await axios.post<R>(`${BASE_URL}${url}${queryString}`, body, {
          headers: { 
            "Content-Type": "application/json",
            Authorization: bearerToken, 
            ...headers },
          ...config,
        });

        setData(response.data);
        setError(null);
      } catch (err: any) {
        if(err?.response?.status === 401)
          logout()
        setError(err.message || "Something went wrong");
        setData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [url]
  );

  const resetResult = useCallback(() => {
      setData(null);
      setError(null);
      setIsLoading(false);
  }, [])

  return [data, error, isLoading, makePostCall, resetResult] as const;
};

export default usePost;
