import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";
import { RoutingType } from "../routing/types";


const columnHelper = createColumnHelper<WorkCenterType>();

export type WorkCenterType = {
    WorkCenterInternalID:string;
    WorkCenterTypeCode:string;
    WorkCenter:string;
    WorkCenterDesc:string;
    Plant:string;
    WorkCenterCategoryCode:string;
    WorkCenterResponsible:string;
    WorkCenterResponsibleName:string;
    SupplyArea:string;
    WorkCenterUsage:string;
    CapacityInternalID:string;
    CapacityCategoryCode:string;
    Capacity:string;
    WorkCenterIsToBeDeleted:string;
    ValidityStartDate:string;
    ValidityEndDate:string;
    WorkCenterFormulaParam1:string;
    WorkCenterFormulaParam2:string;
    WorkCenterFormulaParam3:string;
    WorkCenterFormulaParam4:string;
    WorkCenterFormulaParam5:string;
    WorkCenterFormulaParam6:string;
    WorkCenterFmlaParamValue1:string;
    WorkCenterFmlaParamValue2:string;
    WorkCenterFmlaParamValue3:string;
    WorkCenterFmlaParamValue4:string;
    WorkCenterFmlaParamValue5:string;
    WorkCenterFmlaParamValue6:string;
    WorkCenterFmlaParamUnit1:string;
    WorkCenterFmlaParamUnit2:string;
    WorkCenterFmlaParamUnit3:string;
    WorkCenterFmlaParamUnit4:string;
    WorkCenterFmlaParamUnit5:string;
    StandardWorkFormulaParamGroup:string;
    WrkCtrStdValMaintRule1:string;
    WrkCtrStdValMaintRule2:string;
    WrkCtrStdValMaintRule3:string;
    WrkCtrStdValMaintRule4:string;
    WrkCtrStdValMaintRule5:string;
    WrkCtrStdValMaintRule6:string;
    WorkCenterStandardWorkQtyUnit1:string;
    WorkCenterStandardWorkQtyUnit2:string;
    WorkCenterStandardWorkQtyUnit3:string;
    WorkCenterStandardWorkQtyUnit4:string;
    WorkCenterStandardWorkQtyUnit5:string;
    WorkCenterStandardWorkQtyUnit6:string;
    OperationControlProfile:string;
    MatlCompIsMarkedForBackflush:string;
    WorkCenterLocation:string;
    WorkCenterLocationGroup:string;
    WrkCtrSetupSchedgFmla:string;
    WrkCtrProcgSchedgFmla:string;
    WrkCtrTeardownSchedgFmla:string;
    WrkCtrIntProcgSchedgFmla:string;
    WorkCenterLastChangeDateTime:string;
}
export const columns: ColumnDef<WorkCenterType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "WorkCenterInternalID",
        header: "WorkCenterInternalID",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterTypeCode",
        header: "WorkCenterTypeCode",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Language",
        header: "Language",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterDesc",
        header: "WorkCenterDesc",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenter",
        header: "WorkCenter",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Plant",
        header: "Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterCategoryCode",
        header: "WorkCenterCategoryCode",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterLastChangeDateTime",
        header: "WorkCenterLastChangeDateTime",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterResponsible",
        header: "WorkCenterResponsible",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterResponsibleName",
        header: "WorkCenterResponsibleName",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplyArea",
        header: "SupplyArea",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterUsage",
        header: "WorkCenterUsage",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CapacityInternalID",
        header: "CapacityInternalID",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CapacityCategoryCode",
        header: "CapacityCategoryCode",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Capacity",
        header: "Capacity",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterIsToBeDeleted",
        header: "WorkCenterIsToBeDeleted",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValidityStartDate",
        header: "ValidityStartDate",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValidityEndDate",
        header: "ValidityEndDate",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParam1",
        header: "WorkCenterFormulaParam1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParam2",
        header: "WorkCenterFormulaParam2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParam3",
        header: "WorkCenterFormulaParam3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParam4",
        header: "WorkCenterFormulaParam4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParam5",
        header: "WorkCenterFormulaParam5",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParam6",
        header: "WorkCenterFormulaParam6",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamValue1",
        header: "WorkCenterFormulaParamValue1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamValue2",
        header: "WorkCenterFormulaParamValue2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamValue3",
        header: "WorkCenterFormulaParamValue3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamValue4",
        header: "WorkCenterFormulaParamValue4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamValue5",
        header: "WorkCenterFormulaParamValue5",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamValue6",
        header: "WorkCenterFormulaParamValue6",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamUnit1",
        header: "WorkCenterFormulaParamUnit1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamUnit2",
        header: "WorkCenterFormulaParamUnit2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterFormulaParamUnit3",
        header: "WorkCenterFormulaParamUnit3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },


    {
        accessorKey: "WorkCenterFormulaParamUnit4",
        header: "WorkCenterFormulaParamUnit4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },

    {
        accessorKey: "WorkCenterFormulaParamUnit5",
        header: "WorkCenterFormulaParamUnit5",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },


    {
        accessorKey: "WorkCenterFormulaParamUnit6",
        header: "WorkCenterFormulaParamUnit6",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },

    {
        accessorKey: "StandardWorkFormulaParamGroup",
        header: "StandardWorkFormulaParamGroup",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrStdValMaintRule1",
        header: "WrkCtrStdValMaintRule1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrStdValMaintRule2",
        header: "WrkCtrStdValMaintRule2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrStdValMaintRule3",
        header: "WrkCtrStdValMaintRule3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrStdValMaintRule4",
        header: "WrkCtrStdValMaintRule4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrStdValMaintRule5",
        header: "WrkCtrStdValMaintRule5",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrStdValMaintRule6",
        header: "WrkCtrStdValMaintRule6",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterStandardWorkQtyUnit1",
        header: "WorkCenterStandardWorkQtyUnit1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterStandardWorkQtyUnit2",
        header: "WorkCenterStandardWorkQtyUnit2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterStandardWorkQtyUnit3",
        header: "WorkCenterStandardWorkQtyUnit3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterStandardWorkQtyUnit4",
        header: "WorkCenterStandardWorkQtyUnit4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterStandardWorkQtyUnit5",
        header: "WorkCenterStandardWorkQtyUnit5",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WorkCenterStandardWorkQtyUnit6",
        header: "WorkCenterStandardWorkQtyUnit6",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OperationControlProfile",
        header: "OperationControlProfile",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "MatlCompIsMarkedForBackflush",
        header: "MatlCompIsMarkedForBackflush",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "WorkCenterLocation",
        header: "WorkCenterLocation",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "WorkCenterLocationGroup",
        header: "WorkCenterLocationGroup",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "WrkCtrSetupSchedgFmla",
        header: "WrkCtrSetupSchedgFmla",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrProcgSchedgFmla",
        header: "WrkCtrProcgSchedgFmla",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrTeardownSchedgFmla",
        header: "WrkCtrTeardownSchedgFmla",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "WrkCtrIntProcgSchedgFmla",
        header: "WrkCtrIntProcgSchedgFmla",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },



];