import React, {useCallback, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import NotFound from "../notfound/NotFound";
import DataAcquisitionOverAllStatus from "./over-all-status";
import SourceEndpoints from "./source-endpoints";


const DataAcquisition = () => {
    const location = useLocation();

    const queryParams = location.search.split("=");
    const componentId = queryParams[1];

    const renderSwitch = (id) => {
        switch(id){
          case "/data-acquisition/over-all-status":
            return <DataAcquisitionOverAllStatus />;
          case "/data-acquisition/source-endpoints":
            return <SourceEndpoints />;
          default:
            return <NotFound />;
        }
      }
    
    return (
        <>{renderSwitch(componentId)}</>
    );
};

export default DataAcquisition;