import React from 'react';

const CommonModal = ({ title, closeBtnCallback, yesBtnCallback, yesBtnLabel, disabledFooter=false,  children }) => {
  return (
    // The outer div here uses d-block to force display; in a production scenario,
    // you might control the visibility with a state or a show/hide prop.
    <div
      className="modal fade show d-block"
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {/* Modal Header */}
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeBtnCallback}
            ></button>
          </div>

          {/* Modal Body */}
          <div className="modal-body">
            {children}
          </div>

          {/* Modal Footer */}
          {!disabledFooter && 
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeBtnCallback}
              >
                Close
              </button>
              {yesBtnLabel && <button
                type="button"
                className="btn btn-primary"
                onClick={yesBtnCallback}
              >
                {yesBtnLabel}
              </button>}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CommonModal;
