import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type PurchaseInfoRecord = {
    PurchasingInfoRecord: string
    Supplier: string
    Material: string
    PurchasingOrganization: string
    Plant: string
    MaterialGroup: string
    CreationDate: Date | null
    PurchasingInfoRecordDesc: string
    OrderItemQtyToBaseQtyNmrtr: number
    OrderItemQtyToBaseQtyDnmntr: number
    SupplierMaterialNumber: string
    SupplierRespSalesPersonName: string
    SupplierPhoneNumber: string
    SupplierMaterialGroup: string
    PriorSupplier: string
    AvailabilityStartDate: string | null
    AvailabilityEndDate: string | null
    VarblPurOrdUnitIsActive: string
    Manufacturer: string
    SupplierSubrange: string
    NoDaysReminder1: number
    NoDaysReminder2: number
    NoDaysReminder3: number
    SupplierSubrangeSortNumber: string
    PurchasingInfoRecordCategory:   string;
    CreatedByUser: string
    PurchasingGroup: string
    Currency: string
    MinimumPurchaseOrderQuantity: number
    StandardPurchaseOrderQuantity: number
    MaterialPlannedDeliveryDurn: number
    OverdelivTolrtdLmtRatioInPct: number
    UnderdelivTolrtdLmtRatioInPct: number
    LastReferencingPurchaseOrder: string
    LastReferencingPurOrderItem: string
    NetPriceAmount: number
    MaterialPriceUnitQty: number;
    PriceValidityEndDate: string | null;
    TaxCode: string
    IncotermsClassification: string
    IncotermsTransferLocation: string
    MaximumOrderQuantity: number;
    SupplierQuotation: string
    SupplierQuotationDate: string | null;
    MinRemainingShelfLife: number;
    IsPurOrderAllwdForInbDeliv: string
    ShelfLifeExpirationDatePeriod: string;
    SupplierConfirmationControlKey: string;
    PricingDateControl: string
    MaterialRoundingProfile: string
}

const columnHelper = createColumnHelper<PurchaseInfoRecord>();

export const columns: ColumnDef<PurchaseInfoRecord>[] = [
  columnHelper.display({
    id: "edit",
    cell: EditCell
  }),
  {
    accessorKey: "PurchasingInfoRecord",
    header: "Purchasing Info Record",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "Supplier",
    header: "Supplier",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "Material",
    header: "Material",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "PurchasingOrganization",
    header: "Purchasing Organization",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "Plant",
    header: "Plant",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "MaterialGroup",
    header: "Material Group",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "CreationDate",
    header: "Creation Date",
    cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
    sortUndefined: "last",
    meta: {
      type: "date"
    },
    sortingFn: "datetime",
    "enableGlobalFilter": false
  },
  {
    accessorKey: "PurchasingInfoRecordDesc",
    header: "Purchasing Info Record Desc",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "OrderItemQtyToBaseQtyNmrtr",
    header: "Order Item Qty To Base Qty Nmrtr",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "OrderItemQtyToBaseQtyDnmntr",
    header: "Order Item Qty To Base Qty Dnmntr",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "SupplierMaterialNumber",
    header: "Supplier Material Number",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "SupplierRespSalesPersonName",
    header: "Supplier Resp Sales Person Name",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "SupplierPhoneNumber",
    header: "Supplier Phone Number",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "SupplierMaterialGroup",
    header: "Supplier Material Group",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "PriorSupplier",
    header: "Prior Supplier",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "AvailabilityStartDate",
    header: "Availability Start Date",
    cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
    sortUndefined: "last",
    meta: {
      type: "date"
    },
    sortingFn: "datetime",
    "enableGlobalFilter": false
  },
  {
    accessorKey: "AvailabilityEndDate",
    header: "Availability End Date",
    cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
    sortUndefined: "last",
    meta: {
      type: "date"
    },
    sortingFn: "datetime",
    "enableGlobalFilter": false
  },
  {
    accessorKey: "VarblPurOrdUnitIsActive",
    header: "Varbl Pur Ord Unit Is Active",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "Manufacturer",
    header: "Manufacturer",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "SupplierSubrange",
    header: "Supplier Subrange",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "NoDaysReminder1",
    header: "No Days Reminder1",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "NoDaysReminder2",
    header: "No Days Reminder2",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "NoDaysReminder3",
    header: "No Days Reminder3",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "SupplierSubrangeSortNumber",
    header: "Supplier Subrange Sort Number",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "PurchasingInfoRecordCategory",
    header: "Purchasing Info Record Category",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "CreatedByUser",
    header: "Created By User",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "PurchasingGroup",
    header: "Purchasing Group",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "Currency",
    header: "Currency",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "MinimumPurchaseOrderQuantity",
    header: "Minimum Purchase Order Quantity",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "StandardPurchaseOrderQuantity",
    header: "Standard Purchase Order Quantity",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "MaterialPlannedDeliveryDurn",
    header: "Material Planned Delivery Durn",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "OverdelivTolrtdLmtRatioInPct",
    header: "Overdeliv Tolrtd Lmt Ratio In Pct",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "UnderdelivTolrtdLmtRatioInPct",
    header: "Underdeliv Tolrtd Lmt Ratio In Pct",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "LastReferencingPurchaseOrder",
    header: "Last Referencing Purchase Order",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "LastReferencingPurOrderItem",
    header: "Last Referencing Pur Order Item",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "NetPriceAmount",
    header: "Net Price Amount",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "MaterialPriceUnitQty",
    header: "Material Price Unit Qty",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "PriceValidityEndDate",
    header: "Price Validity End Date",
    cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
    sortUndefined: "last",
    meta: {
      type: "date"
    },
    sortingFn: "datetime",
    "enableGlobalFilter": false
  },
  {
    accessorKey: "TaxCode",
    header: "Tax Code",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "IncotermsClassification",
    header: "Incoterms Classification",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "IncotermsTransferLocation",
    header: "Incoterms Transfer Location",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "MaximumOrderQuantity",
    header: "Maximum Order Quantity",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "SupplierQuotation",
    header: "Supplier Quotation",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "SupplierQuotationDate",
    header: "Supplier Quotation Date",
    cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
    sortUndefined: "last",
    meta: {
      type: "date"
    },
    sortingFn: "datetime",
    "enableGlobalFilter": false
  },
  {
    accessorKey: "MinRemainingShelfLife",
    header: "Min Remaining Shelf Life",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "number"
    },
    filterFn: "equals",
    sortingFn: "basic"
  },
  {
    accessorKey: "IsPurOrderAllwdForInbDeliv",
    header: "Is Pur Order Allwd For Inb Deliv",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "ShelfLifeExpirationDatePeriod",
    header: "Shelf Life Expiration Date Period",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "SupplierConfirmationControlKey",
    header: "Supplier Confirmation Control Key",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "PricingDateControl",
    header: "Pricing Date Control",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  },
  {
    accessorKey: "MaterialRoundingProfile",
    header: "Material Rounding Profile",
    cell: TableCell,
    sortUndefined: "last",
    meta: {
      type: "string"
    },
    filterFn: "includesString",
    sortingFn: "text"
  }
]