import { useState, useCallback } from "react";
import { getAccessToken, logout } from "../helpers/getAccessToken";
import axios, { AxiosRequestConfig } from "axios";

interface GetOptions {
  queryParams?: Record<string, string | number | boolean>;
  headers?: Record<string, string>;
  config?: AxiosRequestConfig;
}

const useGet = <T,>(url: string) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const BASE_URL = "https://verosdata.com/api/";

  const makeGetCall = useCallback(
    async ({ queryParams, headers, config }: GetOptions = {}) => {
      setIsLoading(true);
      setError(null);

      const token = await getAccessToken();
      const bearerToken = `Bearer ${token}`;
      
      try {
        const queryString = queryParams
          ? `?${new URLSearchParams(queryParams as Record<string, string>).toString()}`
          : "";

        const response = await axios.get<T>(`${BASE_URL}${url}${queryString}`, {
          headers: { 
              "Content-Type": "application/json",
              Authorization: bearerToken,
              ...headers },
          ...config,
        });

        setData(response.data);
      } catch (err: any) {
        if(err?.response?.status === 401)
          logout()
        console.log("error : ", err);
        setError(err.message || "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    },
    [url]
  );

  return [data, error, isLoading, makeGetCall] as const;
};

export default useGet;
