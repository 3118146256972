import React, { useCallback, useEffect } from "react";
import "./commontable.css";

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  getFilteredRowModel,
  FilterFn,
  SortingFn,
  getSortedRowModel,
  SortingState,
  getPaginationRowModel,
  PaginationState,
  Row,
  RowData,
  RowSelectionState
} from "@tanstack/react-table";
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';
import ActionsButton from "./action-button"; 
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const FaSortIcon = FaSort as unknown as React.FC;
const FaSortUpIcon = FaSortUp as unknown as React.FC;
const FaSortDownIcon = FaSortDown as unknown as React.FC;

interface CommonTableProps<TData> {
  data: TData[];
  columns: ColumnDef<TData, any>[];
  pageSize ?: number;
  enablePagination ?: boolean;
  enableRowEditing: boolean;
  enablePushToS4?: boolean;
  enableViewTransactionLogs?: boolean;
  enableReferenceLogs?: boolean;
  enableDelete?: boolean;
  enableGlobalFilter?: boolean;
  enableCSVFileUpload?: boolean;
  enableRefresh ?: boolean;
  enableAdd ?: boolean;
  onEdit: (updatedRow: TData | undefined) => void;
  onDelete ?: (selectedItems: RowSelectionState) => void;
  onPushToS4Click ?: (selectedItems: any[]) => void;
  onViewTransactionLogsClick ?: () => void;
  onViewReferenceLogsClick ?: () => void;
  onFileUploadClick ?: () => void;
  onLinkCellClick?: (rowData: TData) => void;
  onRefreshClick?: () => void;
  onAddClick?: () => void;
}


// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {

  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}


const CommonTable = <TData,>({ 
  data, 
  columns, 
  pageSize = 15, 
  enablePagination, 
  enableRowEditing,
  enablePushToS4,
  enableDelete,
  enableViewTransactionLogs,
  enableGlobalFilter,
  enableReferenceLogs,
  enableCSVFileUpload,
  enableRefresh,
  enableAdd, 
  onEdit, 
  onDelete, 
  onPushToS4Click,
  onViewTransactionLogsClick,
  onViewReferenceLogsClick,
  onFileUploadClick,
  onLinkCellClick,
  onRefreshClick,
  onAddClick,
}: CommonTableProps<TData>) => {
  
  const [tableData, setTableData] = React.useState<TData[]>(() => [...data]);
  const [originalData, setOriginalData] = React.useState<TData[]>(() => [...data]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
   // State for pagination, including page index and page size
   const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSize, // Default page size is passed as a prop
  });
  const [editedRows, setEditedRows] = React.useState({});

  const table = useReactTable({
    data: tableData,
    columns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),

    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    globalFilterFn: fuzzyFilter, // Apply fuzzy filtering
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(), // Enable pagination
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,

    getSortedRowModel: getSortedRowModel(), // Enable sorting functionality
    onSortingChange: setSorting,
    onPaginationChange: setPagination, // Handle pagination state changes

    meta: {
      editedRows,
      setEditedRows,
      revertData: (rowIndex: number, revert: boolean) => {
        if (revert) {
          setTableData((old) =>
            old.map((row, index) =>
              index === rowIndex ? originalData[rowIndex] : row
            )
          );
        } else {
          setOriginalData((old) =>
            old.map((row, index) => (index === rowIndex ? data[rowIndex] : row))
          );
        }
      },
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
      onCellClick: (rowData) => {
        console.log("clicked", rowData);
        if(onLinkCellClick)
          onLinkCellClick(rowData)
      }
    },
    state: {
      rowSelection,
      globalFilter, // Manage the global filter state
      sorting, // Manage the sorting state
      pagination, // Bind pagination state
    },
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
  });

  useEffect(()=>{
    console.log("table data is updated")
    setTableData(data);
  }, [data])


  const handleClearInput = useCallback(() => {
    setGlobalFilter('');
  }, []);

  const handlePushToS4Click = () => {
    //TODO: show confirmation modal
    if(onPushToS4Click){
      const selectedRows = table.getSelectedRowModel().rows.map(row => row.original);
      onPushToS4Click(selectedRows);
    }
  }

  const handleDeleteClick = () => {
    if(onDelete){
      onDelete(table.getState().rowSelection);
    }
  }

  const handleViewTransactionLogsClick = () => {
    if(onViewTransactionLogsClick) onViewTransactionLogsClick();
  }

  const handleViewReferenceLogsClick = () => {
    if(onViewReferenceLogsClick) onViewReferenceLogsClick();
  }

  const handleFileUploadClick = () => {
    if(onFileUploadClick) onFileUploadClick();
  }

  const handleRefreshClick = () => {
    if(onRefreshClick) onRefreshClick();
  }

  const handleAddClick = () => {
    if(onAddClick) onAddClick();
  }

  return (
    <div className="common-table-container">
      <div className="common-table-actions-section">
        <div className="common-table-actions-left">
          {enablePushToS4 && <ActionsButton label="Push to S4 Cloud" type="upload" onActionClick={handlePushToS4Click}/>}
          {enableViewTransactionLogs && <ActionsButton label="View S4 Transaction Logs" type="list" onActionClick={handleViewTransactionLogsClick}/>}
          {enableReferenceLogs && <ActionsButton label="View Reference Logs" type="list" onActionClick={handleViewReferenceLogsClick}/>}
          {enableCSVFileUpload && <ActionsButton label="Upload CSV File" type="upload" onActionClick={handleFileUploadClick}/>}
          {enableRefresh && <ActionsButton label="Refresh" type="refresh" onActionClick={handleRefreshClick}/>}
          {enableAdd && <ActionsButton label="Add" type="add" onActionClick={handleAddClick}/>}
          {enableDelete && <ActionsButton label="Delete" type="trash" onActionClick={handleDeleteClick}/>}
        </div>
        <div className="common-table-actions-right">
          {enableGlobalFilter && <>
            <input
              type="text"
              placeholder="Search..."
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="search-filter"
            />
            {globalFilter && (
              <button
                onClick={handleClearInput}
                className="clear-button"
              >
                &#10006;
              </button>
            )}
          </>}
        </div>
      </div>
      <div style={{overflowX:"auto"}}>
      <table className="common-table" style={{width: "100%"}}>
        <thead className="bg-gray-200">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th 
                  key={header.id} 
                  className="p-2 border-b"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className="common-table-header-cell">
                  {header.isPlaceholder
                    ? null
                    : <>{flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {(header.id !== 'select' && header.id !== 'edit') && <>
                        {header.column.getIsSorted() === "asc" ? <FaSortUpIcon /> : header.column.getIsSorted() === "desc" ? <FaSortDownIcon /> : <FaSortIcon />}</>}
                      </>
                  }
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr 
                  key={row.id} 
                  className="border-b table-row"
                >
                  {row.getVisibleCells().map((cell) => { 
                    return(
                      <td key={cell.id} className="p-2 border">
                        {(flexRender(cell.column.columnDef.cell, cell.getContext()))}
                      </td>
                  )})}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="p-4 text-center">
                  No results found
                </td>
              </tr>
            )}
        </tbody>
      </table>
      </div>

      {/* Pagination Controls */}
      {enablePagination && (
        <div className="pagination-controls">
          <div className="pagination-container">
            <span>Total Records: {table.getRowCount()}</span>
            <button
              className=""
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              First
            </button>
            <button
              className=""
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </button>
            
            <span style={{display: "flex"}}>
              <div>Page</div>
              <strong>
                &nbsp;{table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className="">
              | Go to page:
              <input
                type="number"
                min="1"
                max={table.getPageCount()}
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className="border p-1 rounded w-16"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 15, 20, 25, 30].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <button
              className="p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </button>
            <button
              className="p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              Last
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonTable;
