import Odata from "./components/Odata";
import OdataView from "./components/odataView/OdataView";
import Tenant from "./components/Tenant";
import LoadData from "./components/LoadProcess/LoadData";
import TenantEdit from "./components/target-configuration/TenantEdit";
import TenantForm from "./components/target-configuration/tenant_user/TenantForm";
import TenantUserEdit from "./components/target-configuration/tenant_user/TenantUserEdit";
import TenantOpForm from "./components/target-configuration/tenant_op/TenantOpForm";
import TenantOpEdit from "./components/target-configuration/tenant_op/TenantOpEdit";
import Config from "./components/Config";
import Login from "./components/Login/Login";
import Verify from "./components/Login/Verify";
import Product from "./components/migration-cockpit/Product/Product";
import SpecConfig from "./components/SpecConfig";
import Transactions from "./components/Transactions";
import ChangePwd from "./components/ChangePwd";
import VerifyEmail from "./components/forgot-password/VerifyEmail";
import PasswordReset from "./components/forgot-password/PasswordReset";
import CustomerProduction from "./components/CustomerProduction";
import VendorProduction from "./components/VendorProduction";
import UserDetails from "./components/user-details/UserDetails";
import SelectPlant from "./components/data-configuration/select-plant/SelectPlant";
import CustomerConfig from "./components/data-configuration/customer-config/CustomerConfig";
import MigrationCockpit from "./components/migration-cockpit";
import TargetConfiguration from "./components/target-configuration";
import DataConfiguration from "./components/data-configuration";
import Home from "./components/home";
import Playground from "./components/testing/playground";
import DataAcquisition from "./components/data-acquisition";

export const navs = [
  {
    path: "/",
    element: <Login />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/data-acquisition",
    element: <DataAcquisition />,
    isPrivate: true,
    isAdmin: false
  },
  {
    path: "/playground",
    element: <Playground />,
    isPrivate: false,
    isAdmin: false
  },
  {
    path: "/home",
    element: <Home />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/accounts/reset/:uidb/:token",
    element: <PasswordReset />,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/signup",
    element: <Verify />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/user-details",
    element: <UserDetails />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/change-pwd",
    element: <ChangePwd />,
    isPrivate: true,
    isAdmin: false,
  },
  // {
  //   path: "/verify",
  //   element: <Verify />,
  //   isPrivate: false,
  //   isAdmin: true,
  // },
  {
    path: "/migration-cockpit",
    element: <MigrationCockpit />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/config",
    element: <Config />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/sconfig",
    element: <SpecConfig />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/target-configuration",
    element: <TargetConfiguration />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/data-configuration",
    element: <DataConfiguration />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/product-configuration",
    element: <SelectPlant />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant",
    element: <Tenant />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-edit",
    element: <TenantEdit />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-user",
    element: <TenantForm />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-user-edit",
    element: <TenantUserEdit />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-op",
    element: <TenantOpForm />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/tenant-op-edit",
    element: <TenantOpEdit />,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/odata",
    element: <Odata />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/odataView",
    element: <OdataView />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/:object/view",
    element: <Product />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/customerproduction/selection",
    element: <CustomerProduction />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/vendorproduction/selection",
    element: <VendorProduction />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/product/transactions",
    element: <Transactions />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/load-data",
    element: <LoadData />,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/customer-config",
    element: <CustomerConfig />,
    isPrivate: true,
    isAdmin: true,
  },
];
