import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import Bank from "./Bank";
import BPCustomer from "./bp-customer";
import BPSupplier from "./bp-supplier";
import CustomerSalesSelection from "./CustomerSalesSelection";
import WorkCenter from "./workcenter/index";
import NotFound from "../notfound/NotFound";
import PurchaseInfoRecordView from "./purchase-info-record-view/PurchaseInfoRecordView";
import NewProduct from "./new-product";
import SourceList from "./source-list";
import CostCenter from "./cost-center";
import BOM from "./bom";
import Routing from "./routing";
import InspectionPlan from "./inspection-plan";
import Characteristic from "./characteristic";
import Classification from "./classification";
import BPCustomerPartnerFunction from "./bp-customer-partner-function";
import BPSupplierPartnerFunction from "./bp-supplier-partner-function";

export default function MigrationCockpit() {
  const location = useLocation();

  useEffect(()=>{
    console.log("location updated : ", location);
  }, [location]);

  const queryParams = location.search.split("=");
  const componentId = queryParams[1];

  const renderSwitch = (id) => {
    switch(id){
      case "/migration-cockpit/bank":
        return <Bank />;
      case "/migration-cockpit/cost-center":
        return <CostCenter />;
      case "/migration-cockpit/bp-supplier":
        return <BPSupplier />;
      case "/migration-cockpit/bp-customer":
        return <BPCustomer />;
      case "/migration-cockpit/bp-product":
        return <NewProduct />;
      case "/migration-cockpit/routing":
        return <Routing />;
      case "/migration-cockpit/work-center":
        return <WorkCenter />;
      case "/migration-cockpit/customer-sales":
        return <CustomerSalesSelection />;
      case "/migration-cockpit/characteristic":
        return <Characteristic />;
      case "/migration-cockpit/classification":
        return <Classification  />;
      case "/migration-cockpit/bp-customer-partner-function":
        return <BPCustomerPartnerFunction  />;
      case "/migration-cockpit/bp-supplier-partner-function":
        return <BPSupplierPartnerFunction  />;
      case "/migration-cockpit/source-list":
        return <SourceList />;
      case "/migration-cockpit/purchasing-info-record":
          return <PurchaseInfoRecordView />;
      case "/migration-cockpit/bp-bom":
          return <BOM />;
      case "/migration-cockpit/inspection-plan":
          return <InspectionPlan />
      default:
        return <NotFound />;
    }
  }

  return (
    <>
      {renderSwitch(componentId)}
    </>
  );
}
