import React, { useState } from "react";
import CommonModal from "../../common/common-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddSourceEndpointModal = ({showModal, onModalCloseClick}) => {

    const initialValues = {
        name: "",
        user: "",
        password: "",
        baseurl: "",
        is_active: true,
    };

    //yup schema
    const validationSchema = Yup.object().shape({
        name: Yup.string()
          .required("Name is required")
          .min(3, "Name must be at least 3 characters"),
        user: Yup.string()
          .required("User is required")
          .min(3, "User must be at least 3 characters"),
        password: Yup.string()
          .required("Password is required")
          .min(3, "Password must be at least 3 characters"),
        baseurl: Yup.string()
          .required("Base URL is required")
          .url("Must be a valid URL"),
        is_active: Yup.boolean(),
    });


    const handleClose = () => {
        //Clear form
        onModalCloseClick()
    };

    const handleYes = () => {

    }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // if (onSubmitCallback) {
        //   onSubmitCallback(values);
        // }
        // setApiStatus("loading");
        // setApiError(null);
        // try {
        //   // Replace the URL with your actual API endpoint
        //   const response = await fetch("https://your-api-endpoint.com/submit", {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify(values),
        //   });
        //   if (!response.ok) {
        //     throw new Error(`API call failed with status: ${response.status}`);
        //   }
        //   setApiStatus("success");
        //   resetForm();
        // } catch (error) {
        //   setApiError(error.message || "An error occurred");
        //   setApiStatus("error");
        // } finally {
        //   setSubmitting(false);
        // }
    };

    return (
        <div>
            {showModal && (
                <CommonModal
                    title="Add Source Endpoint"
                    closeBtnCallback={handleClose}
                    disabledFooter
                    yesBtnCallback={handleYes}
                    yesBtnLabel="Add Endpoint"
                >   
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                        <Form>
                            {/* Name Field */}
                            <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name
                            </label>
                            <Field type="text" name="name" className="form-control" />
                            <div className="text-danger">
                                <ErrorMessage name="name" />
                            </div>
                            </div>

                            {/* User Field */}
                            <div className="mb-3">
                            <label htmlFor="user" className="form-label">
                                User
                            </label>
                            <Field type="text" name="user" className="form-control" />
                            <div className="text-danger">
                                <ErrorMessage name="user" />
                            </div>
                            </div>

                            {/* Password Field */}
                            <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Password
                            </label>
                            <Field type="password" name="password" className="form-control" />
                            <div className="text-danger">
                                <ErrorMessage name="password" />
                            </div>
                            </div>

                            {/* Base URL Field */}
                            <div className="mb-3">
                            <label htmlFor="baseurl" className="form-label">
                                Base URL
                            </label>
                            <Field type="text" name="baseurl" className="form-control" />
                            <div className="text-danger">
                                <ErrorMessage name="baseurl" />
                            </div>
                            </div>

                            {/* Is Active Field */}
                            <div className="mb-3 form-check">
                            <Field
                                type="checkbox"
                                name="is_active"
                                className="form-check-input"
                                id="is_active"
                            />
                            <label className="form-check-label" htmlFor="is_active">
                                Is Active
                            </label>
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                        )}
                    </Formik>

                    
                </CommonModal>
            )}
        </div>
    )
};

export default AddSourceEndpointModal;