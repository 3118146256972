import { Column, Row, Table, RowData } from "@tanstack/react-table";
import React, { useState, useEffect, ChangeEvent } from "react";


interface TableCellProps<TData extends RowData> {
    getValue: () => any;
    row: Row<TData>;
    table: Table<TData>;
}


const LinkCell = <TData,>({ getValue, row, table }: TableCellProps<TData>) => {
    const value = getValue();
    const handleClick = () => {
      const onCellClick = table.options?.meta?.onCellClick;
      onCellClick?.(row.original);
    };
  
    return (
      <span
        style={{ color: "#0d6efd", cursor: "pointer", textDecoration: "underline" }}
        onClick={handleClick}
      >
        {value}
      </span>
    );
};

export default LinkCell;