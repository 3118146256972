import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../../utils/constants";
import { getAccessToken } from "../../../common/helpers/getAccessToken";

export default function TenantUserEdit() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const { state } = useLocation();
  const [showPwd, setShowPwd] = useState(false);
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    tenant_id: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });
  
  return (
    <div>
      <Formik
        initialValues={{
          tenant_id: state.tenant_id,
          username: state.username,
          password: state.password,
        }}
        validationSchema={Schema}
        onSubmit={async(values, { setSubmitting }) => {
          // console.log(values);
          values.id = state.id;
          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;
          fetch(API_URL + "admin2_update/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
              setSubmitting(false);
            })
            .catch(function (res) {
              console.log(res);
              setSubmitting(false);
              setErr("Error Occurred");
            });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="main">
            <label>Tenant Id :</label>
            <Field name="tenant_id" disabled></Field>
            {errors.tenant_id && touched.tenant_id ? (
              <div className="error">{errors.tenant_id}</div>
            ) : null}
            <label>Username :</label>
            <Field name="username" />
            {errors.username && touched.username ? (
              <div className="error">{errors.username}</div>
            ) : null}
            <label>Password :</label>
            <Field name="password" type={showPwd ? "text" : "password"} />
            {errors.password && touched.password ? (
              <div className="error">{errors.password}</div>
            ) : null}
            <input
              type="checkbox"
              className="pwdToggle"
              onChange={() => setShowPwd(!showPwd)}
              checked={showPwd}
            />
            Show Password
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <div style={{ display: "flex" }}>
              <button className="primary-button" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting...." : "Submit"}
              </button>
              <button
                className="ml-20"
                onClick={() => navigate("/tenant-user-view")}
              >
                Go back
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
