import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type BpSupplierPartnerFunctionType = {
   
Supplier:string;
PurchasingOrganization:string;
SupplierSubrange:string;
Plant:string;
PartnerFunction:string;
PartnerCounter:string;
DefaultPartner:string;
ReferenceSupplier:string;


}

const columnHelper = createColumnHelper<BpSupplierPartnerFunctionType>();


export const columns: ColumnDef<BpSupplierPartnerFunctionType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "Supplier",
        header: "Supplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchasingOrganization",
        header: "PurchasingOrganization",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierSubrange",
        header: "SupplierSubrange",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Plant",
        header: "Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PartnerFunction",
        header: "PartnerFunction",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PartnerCounter",
        header: "PartnerCounter",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DefaultPartner",
        header: "DefaultPartner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ReferenceSupplier",
        header: "ReferenceSupplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }
];