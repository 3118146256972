import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { RowSelectionState } from "@tanstack/react-table";
import * as Yup from "yup";
import { FaArrowLeft } from "react-icons/fa";
import useGet from "../../../common/hooks/useGet";
import usePost from "../../../common/hooks/usePost";
import MultiSelect from "../../multi-select/MultiSelect";
import CommonTable from "../../../common/common-table/common-table";
import SelectionCheckbox from "../../../common/common-table/selection-checkbox";
import { columns } from "./types";
import BasicTable from "../../../common/basic-table/basic-table";

const TYPE_AHEAD_OPTIONS_API = "classification-typehead"; // Replace with your actual API endpoint
const SEARCH_API = "classification-view";
const S4_PUSH_API = "classification-push";
const REFERENCE_LOGS_API = "reference-tracking-logs?object=classfication";
const TRANSACTION_LOGS_API = "legacy-transaction-logs?object=classfication";


const validationSchema = Yup.object({
    ClassInternalID: Yup.array().required("Required"),
});

const INITIAL_VALUES = {
    ClassInternalID: "*",
};

const MODE = Object.freeze({
    "SEARCH_FORM": "SEARCH_FORM",
    "SEARCH_RESULTS": "SEARCH_RESULTS",
    "TRANSACTION_LOGS": "TRANSACTION_LOGS",
    "REFERENCE_LOGS": "REFERENCE_LOGS"
});
const headerSelectCell = {
    id: 'select',
    header: ({ table }) => (
      <SelectionCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
        <SelectionCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
    ),
};

const Classification = () => {
    const [mode, setMode] = useState(MODE.SEARCH_FORM);
    const [optionsData, optionsError, isOptionsLoading, makeGetOptionsCall] = useGet(TYPE_AHEAD_OPTIONS_API);
    const [searchResults, searchResultsError, isSearchResultsLoading, makeSearchResultsPostCall, resetSearchResultsResult] = usePost(SEARCH_API);
    const [data, setData] = useState(null);
    
    const [s4PushResult, s4PushError, isS4PushLoading, makeS4PushPostCall, resetS4PushResult] = usePost(S4_PUSH_API);
    const [transactionLogs, transactionLogsError, isTransactionLogsLoading, makeGetTransactionLogsCall]= useGet(TRANSACTION_LOGS_API);
    const [referenceLogs, referenceLogsError, isReferenceLogsLoading, makeGetReferenceLogsCall]= useGet(REFERENCE_LOGS_API);

    const isLoading = isSearchResultsLoading || isS4PushLoading || isTransactionLogsLoading || isReferenceLogsLoading;
    const error = searchResultsError || s4PushError || transactionLogsError || referenceLogsError;

    // Fetch select options from API
    useEffect(() => {
        makeGetOptionsCall()
    }, [makeGetOptionsCall]);

    const handleSubmit = async (values) => {
        console.log("handle submit", values)
        const body = {...values}
        makeSearchResultsPostCall({body});
        setMode(MODE.SEARCH_RESULTS)
    };

    const handleEdit = useCallback((updatedRow) => {
        setData((prevData) =>
          prevData.map((row) => (row.id === updatedRow.id ? updatedRow : row))
        );
    },[]);

    // Callback for delete action
    const handleDelete = useCallback((selectedItems) => {
        const selectedIds = Object.keys(selectedItems);
        const selectedRows = data.filter(row => selectedIds.includes(row.id));
        console.log(selectedRows);
    },[data]);

    const handlePushToS4 = useCallback((selectedItems)=>{
        const selectedRowPayload = selectedItems.map(item => ({ClassInternalID: item.ClassInternalID}));
        console.log(JSON.stringify(selectedRowPayload));
        // Prepare the request payload
        let requestPayload = [
            ...selectedRowPayload,
            {object_name : ["classification"]}
        ];
        console.log(requestPayload)
        makeS4PushPostCall({body: requestPayload});
        
    }, [makeS4PushPostCall]);

    const handleViewTransactionLogs = useCallback(()=>{
        console.log("***Show Transaction Logs**");
        setMode(MODE.TRANSACTION_LOGS);
        makeGetTransactionLogsCall();
    },[]);

    const handleViewReferenceLogs = useCallback(()=>{
        console.log("***Show Reference Logs**");
        setMode(MODE.REFERENCE_LOGS);
        makeGetReferenceLogsCall();
    },[]);

    const handleBackButtonClick = useCallback(() => {
        resetS4PushResult();
        if(mode === MODE.SEARCH_RESULTS){
            setMode(MODE.SEARCH_FORM);
            resetSearchResultsResult();
        }
        else if(mode === MODE.REFERENCE_LOGS){
            setMode(MODE.SEARCH_RESULTS);
        }
        else if(mode === MODE.TRANSACTION_LOGS){
            setMode(MODE.SEARCH_RESULTS);
        }
    }, [mode, resetSearchResultsResult, resetS4PushResult]);

    return (
    <div>
        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", margin: "15px"}}>
            { mode !== MODE.SEARCH_FORM && <button
                onClick={handleBackButtonClick}
                className="action-button border-none">
                <FaArrowLeft /> Back 
            </button>}
            <strong>Classification</strong> 
            {mode === MODE.TRANSACTION_LOGS && <strong>/ Transaction Logs</strong> }
            {mode === MODE.REFERENCE_LOGS && <strong>/ Reference Logs</strong> }
        </div>
        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", margin: "15px"}}>
            {isS4PushLoading && <p className="loading-status"> API call is in progress...    </p>}
            {!isS4PushLoading && s4PushError && <p className="error-status">{JSON.stringify(s4PushError)}</p>}
            {!isS4PushLoading && s4PushResult && <p className="api-status-msg">{JSON.stringify(s4PushResult)}</p>}
        </div>
        {mode === MODE.SEARCH_FORM && <>
            <Formik
                initialValues={INITIAL_VALUES}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className="main">
                        <div>
                            <label htmlFor="ClassInternalID" className="">Classification ID</label>
                            <MultiSelect
                                vals={optionsData?.ClassInternalID ? ['*', ...optionsData?.ClassInternalID] : ['*']}
                                fieldName="ClassInternalID"
                                setValues={setFieldValue}
                            />
                            <ErrorMessage name="ClassInternalID" component="div" className="error" />
                        </div>
                        <button type="submit" disabled={isSubmitting || isSearchResultsLoading} className="primary-button">
                            {isSearchResultsLoading ? "Searching..." : "Search"}
                        </button>
                        {searchResultsError && <p className="text-red-500 text-sm">{searchResultsError}</p>}
                    </Form>
                )}
            </Formik>
        </>}
        {(mode === MODE.SEARCH_RESULTS && !isSearchResultsLoading && !searchResultsError) && 
            <> 
                <CommonTable 
                    data={searchResults}
                    columns={[headerSelectCell, ...columns]}
                    pageSize={15}
                    enablePagination
                    enableRowEditing
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onPushToS4Click={handlePushToS4}
                    enablePushToS4
                    enableViewTransactionLogs
                    enableGlobalFilter
                    onViewTransactionLogsClick={handleViewTransactionLogs}
                    enableReferenceLogs
                    onViewReferenceLogsClick={handleViewReferenceLogs}
                />
            </>
        }
        {(mode === MODE.TRANSACTION_LOGS && (isTransactionLogsLoading || transactionLogsError)) && 
            <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", margin: "15px"}}>
                {isTransactionLogsLoading && <p className="loading-status">API call is in progress...</p>}
                {!isTransactionLogsLoading && transactionLogsError && <p className="error-status">{JSON.stringify(transactionLogsError)}</p>}
            </div>
        }
        {(mode === MODE.TRANSACTION_LOGS && !isTransactionLogsLoading && !transactionLogsError) && <>
            <BasicTable data={transactionLogs}/>
        </>}
        {(mode === MODE.REFERENCE_LOGS && (isReferenceLogsLoading || referenceLogsError)) && 
            <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", margin: "15px"}}>
                {isReferenceLogsLoading && <p className="loading-status">API call is in progress...</p>}
                {!isReferenceLogsLoading && referenceLogsError && <p className="error-status">{JSON.stringify(referenceLogsError)}</p>}
            </div>
        }
        {(mode === MODE.REFERENCE_LOGS && !isReferenceLogsLoading && !referenceLogsError)&& <>
            <BasicTable data={referenceLogs}/>
        </>}
        
    </div>
  );
};

export default Classification;