import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type BpCustomerType = {
    Customer:                       string;
    BusinessPartner:                string;
    CompanyCode:                    string;
    SalesOrganization:              string;
    DistributionChannel:            string;
    Division:                       string;
    AuthorizationGroup:             string;
    CorrespondenceLanguage:         string;
    Language:                       string;
    FirstName:                      string;
    FormofAddress:                  string;
    Industry:                       string;
    InternationalLocationNumber1:   string;
    InternationalLocationNumber2:   string;
    IsNaturalPerson:                string;
    OrganizationBPName1:            string;
    OrganizationBPName2:            string;
    OrganizationBPName3:            string;
    OrganizationBPName4:            string;
    SearchTerm1:                    string;
    SearchTerm2:                    string;
    NameCountry:                    string;
    TradingPartner:                 string;
    AccountByCustomer:              string;
    BillingIsBlockedForCustomer:    string;
    CompleteDeliveryIsDefined:      string;
    CreditControlArea:              string;
    Currency:                       string;
    CustomerABCClassification:      string;
    CustomerAccountAssignmentGroup: string;
    CustomerGroup:                  string;
    CustomerPaymentTerms:           string;
    CustomerPriceGroup:             string;
    CustomerPricingProcedure:       string;
    DeliveryIsBlockedForCustomer:   string;
    DeliveryPriority:               string;
    IncotermsLocation2:             string;
    IncotermsLocation1:             string;
    ItemOrderProbabilityInPercent:  number;
    MaxNmbrOfPartialDelivery:       number;
    OrderIsBlockedForCustomer:      string;
    OverdelivTolrtdLmtRatioInPct:   number;
    PartialDeliveryIsAllowed:       string;
    PriceListType:                  string;
    SalesGroup:                     string;
    SalesOffice:                    string;
    ShippingCondition:              string;
    SupplyingPlant:                 string;
    SalesDistrict:                  string;
    UnderdelivTolrtdLmtRatioInPct:  number;
    AdditionalCustomerGroup1:       string;
    AdditionalCustomerGroup2:       string;
    AdditionalCustomerGroup3:       string;
    AdditionalCustomerGroup4:       string;
    AdditionalCustomerGroup5:       string;
    CustomerAccountGroup:           string;
    APARToleranceGroup:             string;
    AccountingClerk:                string;
    AccountingClerkFaxNumber:       string;
    AccountingClerkInternetAddress: string;
    AccountingClerkPhoneNumber:     string;
    AlternativePayerAccount:        string;
    CollectiveInvoiceVariant:       string;
    CustomerAccountNote:            string;
    CustomerHeadOffice:             string;
    HouseBank:                      string;
    InterestCalculationCode:        string;
    InterestCalculationDate:        string;
    IntrstCalcFrequencyInMonths:    string;
    LayoutSortingRule:              string;
    PaymentBlockingReason:          string;
    PaymentMethodsList:             string;
    PaymentTerms:                   string;
    ReconciliationAccount:          string;
    RecordPaymentHistoryIndicator:  string;
    UserAtCustomer:                 string;
    CashPlanningGroup:              string;
    KnownOrNegotiatedLeave:         string;
    ValueAdjustmentKey:             string;
}

const columnHelper = createColumnHelper<BpCustomerType>();


export const columns: ColumnDef<BpCustomerType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "Customer",
        header: "Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BusinessPartner",
        header: "Business Partner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CompanyCode",
        header: "Company Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesOrganization",
        header: "Sales Organization",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DistributionChannel",
        header: "Distribution Channel",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Division",
        header: "Division",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AuthorizationGroup",
        header: "Authorization Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CorrespondenceLanguage",
        header: "Correspondence Language",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Language",
        header: "Language",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "FirstName",
        header: "First Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "FormofAddress",
        header: "Form of Address",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Industry",
        header: "Industry",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InternationalLocationNumber1",
        header: "International Location Number1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InternationalLocationNumber2",
        header: "International Location Number2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsNaturalPerson",
        header: "Is Natural Person",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName1",
        header: "Organization BP Name1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName2",
        header: "Organization B P Name2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName3",
        header: "Organization B P Name3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName4",
        header: "Organization BP Name4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SearchTerm1",
        header: "Search Term 1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SearchTerm2",
        header: "Search Term 2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "NameCountry",
        header: "Name Country",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "TradingPartner",
        header: "Trading Partner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AccountByCustomer",
        header: "Account By Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillingIsBlockedForCustomer",
        header: "Billing Is Blocked For Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CompleteDeliveryIsDefined",
        header: "Complete Delivery Is Defined",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CreditControlArea",
        header: "Credit Control Area",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Currency",
        header: "Currency",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerABCClassification",
        header: "Customer ABC Classification",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerAccountAssignmentGroup",
        header: "Customer Account Assignment Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerGroup",
        header: "Customer Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerPaymentTerms",
        header: "Customer Payment Terms",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text",
    },
    {
        accessorKey: "CustomerPriceGroup",
        header: "Customer Price Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerPricingProcedure",
        header: "Customer Pricing Procedure",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DeliveryIsBlockedForCustomer",
        header: "Delivery Is Blocked For Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DeliveryPriority",
        header: "Delivery Priority",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IncotermsLocation2",
        header: "Incoterms Location 2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IncotermsLocation1",
        header: "Incoterms Location 1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ItemOrderProbabilityInPercent",
        header: "Item Order Probability In Percent",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "MaxNmbrOfPartialDelivery",
        header: "Max Nmbr Of Partial Delivery",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "OrderIsBlockedForCustomer",
        header: "Order Is Blocked For Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OverdelivTolrtdLmtRatioInPct",
        header: "Overdeliv Tolrtd Lmt Ratio In Pct",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "PartialDeliveryIsAllowed",
        header: "Partial Delivery Is Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PriceListType",
        header: "Price List Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesGroup",
        header: "Sales Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesOffice",
        header: "Sales Office",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ShippingCondition",
        header: "Shipping Condition",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplyingPlant",
        header: "Supplying Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesDistrict",
        header: "Sales District",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text",
    },
    {
        accessorKey: "UnderdelivTolrtdLmtRatioInPct",
        header: "Underdeliv Tolrtd Lmt Ratio In Pct",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "AdditionalCustomerGroup1",
        header: "Additional Customer Group1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AdditionalCustomerGroup2",
        header: "Additional Customer Group2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AdditionalCustomerGroup3",
        header: "Additional Customer Group3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AdditionalCustomerGroup4",
        header: "Additional Customer Group4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AdditionalCustomerGroup5",
        header: "Additional Customer Group5",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerAccountGroup",
        header: "Customer Account Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "APARToleranceGroup",
        header: "APAR Tolerance Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AccountingClerk",
        header: "Accounting Clerk",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AccountingClerkFaxNumber",
        header: "Accounting Clerk Fax Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AccountingClerkInternetAddress",
        header: "Accounting Clerk Internet Address",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AccountingClerkPhoneNumber",
        header: "Accounting Clerk Phone Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AlternativePayerAccount",
        header: "Alternative Payer Account",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CollectiveInvoiceVariant",
        header: "Collective Invoice Variant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerAccountNote",
        header: "Customer Account Note",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerHeadOffice",
        header: "Customer Head Office",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "HouseBank",
        header: "House Bank",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InterestCalculationCode",
        header: "Interest Calculation Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InterestCalculationDate",
        header: "Interest Calculation Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "IntrstCalcFrequencyInMonths",
        header: "Intrst Calc Frequency In Months",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "LayoutSortingRule",
        header: "Layout Sorting Rule",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PaymentBlockingReason",
        header: "Payment Blocking Reason",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PaymentMethodsList",
        header: "Payment Methods List",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PaymentTerms",
        header: "Payment Terms",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ReconciliationAccount",
        header: "Reconciliation Account",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "RecordPaymentHistoryIndicator",
        header: "Record Payment History Indicator",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "UserAtCustomer",
        header: "User At Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CashPlanningGroup",
        header: "Cash Planning Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "KnownOrNegotiatedLeave",
        header: "Known Or Negotiated Leave",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValueAdjustmentKey",
        header: "Value Adjustment Key",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }
];