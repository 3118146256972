import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthData } from '../../App';
import cx from "classnames";
import './menu-bar.css';

const RenderSubMenu = ({ submenu, onSubmenuSelect }) => {
    return (
        <div className="submenu">
            {submenu.map(category => {
                return (
                    <div key={category.name}>
                        <h5>{category.name}</h5>
                        <ol>
                            {category.subMenu.map((cat) => {
                                return (
                                    <li key={cat.path} className="submenu-item">
                                        <a onClick={() => onSubmenuSelect(cat)}>{cat.name}</a>
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                );
            })}
        </div>
    );
};




const menuItems = [
    { name: "Home", path: "/home", hasSubmenu: false },
    { 
        name: "Date Acquisition", 
        path: "/data-acquisition", 
        hasSubmenu: true,
        subMenu: [
            {
                name: "Data Acquisition",
                subMenu: [
                    { path: "/data-acquisition/over-all-status", name: "Over All Status", enabled: true },
                    { path: "/data-acquisition/source-endpoints", name: "Source Endpoints", enabled: true }
                ],
            }
        ] 
    },
    {
        name: 'Migration Cockpit',
        path: "/migration-cockpit",
        hasSubmenu: true,
        subMenu: [
            {
                name: "Finance",
                subMenu: [
                    { path: "/migration-cockpit/bank", name: "Bank", enabled: true, },
                    { path: "/migration-cockpit/profit-center", name: "Profit Center", enabled: true },
                    { path: "/migration-cockpit/cost-center", name: "Cost Center", enabled: true },
                    { path: "/migration-cockpit/g-l-account", name: "G/L Account", enabled: true },
                    { path: "/migration-cockpit/asset", name: "Asset", enabled: true },
                    { path: "/migration-cockpit/cost-element", name: "Cost Element", enabled: true },
                    { path: "/migration-cockpit/activity-type", name: "Activity Type", enabled: true },
                ],
            },
            {
                name: "General Master Data",
                subMenu: [
                    { path: "/migration-cockpit/bp-supplier", name: "BP-Supplier" },
                    { path: "/migration-cockpit/bp-supplier-partner-function", name: "BP-Supplier Partner Function" },
                    { path: "/migration-cockpit/bp-customer", name: "BP-Customer" },
                    { path: "/migration-cockpit/bp-customer-partner-function", name: "BP-Customer Partner Function" },
                    { path: "/migration-cockpit/bp-employee", name: "BP-Employee" },
                    { path: "/migration-cockpit/bp-product", name: "Product" },
                    { path: "/migration-cockpit/bp-bom", name: "BOM" },
                    { path: "/migration-cockpit/bp-change-number", name: "Change Number" },
                ],
            },
            {
                name: "Purchasing",
                subMenu: [
                    { path: "/migration-cockpit/purchasing-info-record", name: "Purchasing Info Record" },
                    { path: "/migration-cockpit/source-list", name: "Source List" }
                ],
            },
            {
                name: "Manufacturing",
                subMenu: [
                    { path: "/migration-cockpit/routing", name: "Routing" },
                    { path: "/migration-cockpit/work-center", name: "Work center" }
                ],
            },
            {
                name: "Quality Management Master",
                subMenu: [
                    { path: "/migration-cockpit/inspection-plan", name: "Inspection Plan" },
                    { path: "/migration-cockpit/mic", name: "MIC" },
                    { path: "/migration-cockpit/qa-insp-pro", name: "QA Insp. Pro" },
                    { path: "/migration-cockpit/characteristic", name: "Characteristic" },
                    { path: "/migration-cockpit/classification", name: "Classification" },
                ],
            },
        ]
    },
    {
        name: 'Data Configuration',
        path: '/data-configuration',
        hasSubmenu: true,
        subMenu: [
            {
                name: "Data Configuration",
                subMenu: [
                    { path: "/data-configuration/product-configuration", name: "Product Configuration", enabled: true },
                    { path: "/data-configuration/configuration-for-customer", name: "Customer / Vendor Configuration", enabled: true },
                ],
            },
        ],
    },
    { name: "Load Data", path: "/load-data", hasSubmenu: false },
    { name: "Data Lake", path: "/odataView", hasSubmenu: false },
    {
        name: 'Target Configuration',
        path: '/target-configuration',
        hasSubmenu: true,
        subMenu: [
            {
                name: "Target Configuration",
                subMenu: [
                    { path: "/target-configuration/tenant", name: "Tenant", enabled: true },
                    { path: "/target-configuration/tenant-user", name: "Tenant User", enabled: true },
                    { path: "/target-configuration/tenant-operation", name: "Tenant Operation", enabled: true }
                ],
            },
        ],
    },
    { name: "Users", path: "/user-details", hasSubmenu: false }
];

function findObjectByPath(menu, targetPath) {
    for (const item of menu) {
        if (item.path === targetPath) {
            return item;
        }
        if (item.subMenu) {
            const found = findObjectByPath(item.subMenu, targetPath);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

const MenuBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = AuthData();
    const [activeItem, setActiveItem] = useState({ path: "/home", name: "Home", enabled: true });
    const [openSubmenu, setOpenSubmenu] = useState(null);

    const handleClick = (selectedItem) => {
        if (!selectedItem?.hasSubmenu) {
            const newPath = selectedItem.path.split("/");
            setActiveItem(selectedItem);
            navigate(`/${newPath[1]}?id=${selectedItem.path}`);
            setOpenSubmenu(null);
        } else {
            setOpenSubmenu(openSubmenu === selectedItem.path ? null : selectedItem.path);
        }
    };

    useEffect(() => {
        const pathKey = location.search.substring(4);
        if (pathKey) {
            const pathObj = findObjectByPath(menuItems, pathKey);
            setActiveItem(pathObj);
        }
    }, [location]);

    const handleSubmenuItemClick = (selectedItem) => {
        const newPath = selectedItem.path.split("/");
        setActiveItem(selectedItem);
        navigate(`/${newPath[1]}?id=${selectedItem.path}`);
        setOpenSubmenu(null);
    };

    return (
        <>
            <div className="menu-container">
                <ul className="menu-list">
                    {menuItems.map((item) => (
                        <li
                            key={item.path}
                            className={cx("menu-item", activeItem.path.includes(item.path) && "active")}
                        >
                            <>
                                <a className="menu-link" onClick={() => handleClick(item)}>
                                    {item.name}
                                </a>
                                {item.subMenu && item.subMenu.length > 0 && openSubmenu === item.path && (
                                    <div className={`submenu-container`}>
                                        <RenderSubMenu
                                            submenu={item.subMenu}
                                            onSubmenuSelect={handleSubmenuItemClick}
                                        />
                                    </div>
                                )}
                            </>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default MenuBar;
