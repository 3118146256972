import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";

export type ProductType = {
    Product:                       string;
    ProductType:                   string;
    CrossPlantStatus:              string;
    CreationDate:                  number;
    LastChangedByUser:             string;
    ProductOldID:                  string;
    GrossWeight:                   number;
    PurchaseOrderQuantityUnit:     string;
    SourceOfSupply:                string;
    WeightUnit:                    string;
    NetWeight:                     number;
    CountryOfOrigin:               string;
    CompetitorID:                  string;
    ProductGroup:                  string;
    BaseUnit:                      string;
    ItemCategoryGroup:             string;
    ProductHierarchy:              string;
    Division:                      string;
    VarblPurOrdUnitIsActive:       string;
    VolumeUnit:                    string;
    MaterialVolume:                number;
    ANPCode:                       string;
    Brand:                         string;
    ProcurementRule:               string;
    LowLevelCode:                  string;
    ProdNoInGenProdInPrepackProd:  string;
    SerialIdentifierAssgmtProfile: string;
    SizeOrDimensionText:           string;
    IndustryStandardName:          string;
    InternationalArticleNumberCat: string;
    ProductIsConfigurable:         string;
    IsBatchManagementRequired:     string;
    ExternalProductGroup:          string;
    CrossPlantConfigurableProduct: string;
    SerialNoExplicitnessLevel:     string;
    ProductManufacturerNumber:     string;
    ManufacturerNumber:            string;
    ManufacturerPartProfile:       string;
    QltyMgmtInProcmtIsActive:      string;
    IndustrySector:                string;
    ChangeNumber:                  string;
    MaterialRevisionLevel:         string;
    HandlingIndicator:             string;
    WarehouseProductGroup:         string;
    WarehouseStorageCondition:     string;
    StandardHandlingUnitType:      string;
    SerialNumberProfile:           string;
    AdjustmentProfile:             string;
    IsPilferable:                  string;
    QuarantinePeriod:              number;
    TimeUnitForQuarantinePeriod:   string;
    QualityInspectionGroup:        string;
    AuthorizationGroup:            string;
    HandlingUnitType:              string;
    HasVariableTareWeight:         string;
    MaximumPackagingLength:        number;
    MaximumPackagingWidth:         number;
    MaximumPackagingHeight:        number;
    UnitForMaxPackagingDimensions: string;
    Plant:                         string;
}
const columnHelper = createColumnHelper<ProductType>();

export const columns: ColumnDef<ProductType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
       accessorKey:"Product",
       header:"Product",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProductType",
       header:"Product Type",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"CrossPlantStatus",
       header:"Cross Plant Status",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"CreationDate",
       header:"Creation Date",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"LastChangedByUser",
       header:"Last Changed By User",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProductOldID",
       header:"Product Old ID",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"GrossWeight",
       header:"Gross Weight",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"PurchaseOrderQuantityUnit",
       header:"Purchase Order Quantity Unit",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"SourceOfSupply",
       header:"Source Of Supply",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"WeightUnit",
       header:"Weight Unit",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"NetWeight",
       header:"Net Weight",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"CountryOfOrigin",
       header:"Country Of Origin",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"CompetitorID",
       header:"Competitor ID",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProductGroup",
       header:"Product Group",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"BaseUnit",
       header:"Base Unit",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ItemCategoryGroup",
       header:"Item Category Group",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProductHierarchy",
       header:"Product Hierarchy",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"Division",
       header:"Division",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"VarblPurOrdUnitIsActive",
       header:"Varbl Pur Ord Unit Is Active",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"VolumeUnit",
       header:"Volume Unit",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"MaterialVolume",
       header:"Material Volume",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"ANPCode",
       header:"ANP Code",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"Brand",
       header:"Brand",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProcurementRule",
       header:"Procurement Rule",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"LowLevelCode",
       header:"Low Level Code",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProdNoInGenProdInPrepackProd",
       header:"Prod No In Gen Prod In Prepack Prod",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"SerialIdentifierAssgmtProfile",
       header:"Serial Identifier Assgmt Profile",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"SizeOrDimensionText",
       header:"Size Or Dimension Text",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"IndustryStandardName",
       header:"Industry Standard Name",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"InternationalArticleNumberCat",
       header:"International Article Number Cat",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProductIsConfigurable",
       header:"Product Is Configurable",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"IsBatchManagementRequired",
       header:"Is Batch Management Required",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ExternalProductGroup",
       header:"External Product Group",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"CrossPlantConfigurableProduct",
       header:"Cross Plant Configurable Product",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"SerialNoExplicitnessLevel",
       header:"Serial No Explicitness Level",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ProductManufacturerNumber",
       header:"Product Manufacturer Number",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ManufacturerNumber",
       header:"Manufacturer Number",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ManufacturerPartProfile",
       header:"Manufacturer Part Profile",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"QltyMgmtInProcmtIsActive",
       header:"Qlty Mgmt In Procmt Is Active",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"IndustrySector",
       header:"Industry Sector",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"ChangeNumber",
       header:"Change Number",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"MaterialRevisionLevel",
       header:"Material Revision Level",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"HandlingIndicator",
       header:"Handling Indicator",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"WarehouseProductGroup",
       header:"Warehouse Product Group",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"WarehouseStorageCondition",
       header:"Warehouse Storage Condition",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"StandardHandlingUnitType",
       header:"Standard Handling Unit Type",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"SerialNumberProfile",
       header:"Serial Number Profile",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"AdjustmentProfile",
       header:"Adjustment Profile",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"IsPilferable",
       header:"Is Pilferable",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"QuarantinePeriod",
       header:"Quarantine Period",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"TimeUnitForQuarantinePeriod",
       header:"Time Unit For Quarantine Period",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"QualityInspectionGroup",
       header:"Quality Inspection Group",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"AuthorizationGroup",
       header:"Authorization Group",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"HandlingUnitType",
       header:"Handling Unit Type",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"HasVariableTareWeight",
       header:"Has Variable Tare Weight",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"MaximumPackagingLength",
       header:"Maximum Packaging Length",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"MaximumPackagingWidth",
       header:"Maximum Packaging Width",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"MaximumPackagingHeight",
       header:"Maximum Packaging Height",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"number"
       },
       filterFn:"equals",
       sortingFn:"basic"
    },
    {
       accessorKey:"UnitForMaxPackagingDimensions",
       header:"Unit For Max Packaging Dimensions",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    },
    {
       accessorKey:"Plant",
       header:"Plant",
       cell: TableCell,
       sortUndefined:"last",
       meta:{
          type:"string"
       },
       filterFn:"includesString",
       sortingFn:"text"
    }
 ]