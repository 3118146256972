import React, { useEffect, useState } from "react";
import classes from "./userdetaile.module.css";
import Overlay from "../overlay/Overlay";
import { API_URL } from "../../utils/constants";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../common/helpers/getAccessToken";

export default function UserDetails() {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");

  
 

  useEffect(() => {
    const fetchData = async () => {

      const token = await getAccessToken();
      const bearerToken = `Bearer ${token}`;
      fetch(API_URL + "get-users/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
      })
        .then((data) => {
          return data.json();
        })
        .then((post) => {
          setData(post);
        })
        .catch((err) => {});
    }
    fetchData()
  }, []);

  const removeUser = async(email) => {
    setMsg("Deleting....");
    const token = await getAccessToken();
    const bearerToken = `Bearer ${token}`;
    fetch(API_URL + "delete-users/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((dat) => {
        if (dat.status === 404) throw new Error("not found");
        let temp = data.filter((d) => d.email !== email);
        setData(temp);
        setMsg("Deleted");
      })
      .catch((err) => {
        console.log(err);
        setMsg("Unable to delete try again!!");
      });
  };

  const addUser = () => {
    navigate(`/signup`);
  }

  return (
    <div className="log-con">
      <button className="primary-button" style={{width: "150px"}} onClick={addUser}>Add User</button>
      {msg && (
        <div className={classes.status}>
          <div className={classes.fail}>{msg}</div>
          <div>
            <button onClick={() => setMsg("")}>X</button>
          </div>
        </div>
      )}
      {data.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                {Object.keys(data[0]).map((x) => {
                  return (
                    <th key={x}>
                      <strong>{x}</strong>
                    </th>
                  );
                })}
                <th>
                  <strong>Actions</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d.username}>
                    {Object.values(d).map((x) => {
                      return <td key={x}>{x}</td>;
                    })}
                    <td
                      style={{
                        fontSize: "large",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <div
                        style={{ color: "#d62931", cursor: "pointer" }}
                        onClick={() => removeUser(d.email)}
                      >
                        <AiOutlineDelete />
                      </div>
                      <div style={{ cursor: "pointer" }} onClick={() => {}}>
                        <FaEdit />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <Overlay closeMethod={() => {}} isLoader={true}></Overlay>
      )}
    </div>
  );
}
