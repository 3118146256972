import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { AuthData } from "../../App";
import { isTokenValid } from "../../common/helpers/isTokenValid";

export default function Login() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const { user, login } = AuthData();

  const isAuthenticated = isTokenValid();
  
  useEffect(() => {
    if (isAuthenticated) navigate("/home");
  }, [isAuthenticated, navigate]);
  
  const handleLogin = async (e) => {
    e.preventDefault();
    let uname = e.target[0].value;
    let pwd = e.target[1].value;
    if (!uname || !pwd) {
      setErr("Invalid Credential");
    } else {
      setErr(false);
      try {
        let resp = await login(uname, pwd);
        console.log(resp);
      } catch (err) {
        setErr(err);
      }
    }
  };

  return (
    <div className="main">
      <h3>{"Enter your login credentials"}</h3>
      <form onSubmit={handleLogin}>
        <label htmlFor="first">Email :</label>
        <input
          type="text"
          id="first"
          name="first"
          autoComplete="off"
          placeholder="Enter your email"
        />
        <label htmlFor="password">Password :</label>
        <input
          type={showPwd ? "text" : "password"}
          id="password"
          name="password"
          placeholder="Enter your Password"
        />
        <input
          type="checkbox"
          className="pwdToggle"
          onChange={() => setShowPwd(!showPwd)}
          checked={showPwd}
        />
        Show Password
        {err && <p className="error">{err}</p>}
        <p onClick={() => navigate("/verify-email")} className="dec">
          {"Forgot Password ?"}
        </p>
        <div className="wrap">
          <button className="primary-button" type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}
