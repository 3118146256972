import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type RoutingType = {
    Product: string;
    Plant: string;
    ProductionRoutingGroup: string;
    ProductionRouting: string;
    ProductionRoutingMatlAssgmt: string;
    ProductionRtgMatlAssgmtIntVers: string;
    CreationDate: number;
    CreatedByUser: string;
    LastChangeDate: number;
    LastChangedByUser: string;
    ValidityStartDate: string;
    ChangeNumber: string;
    SalesOrder: string;
    SalesOrderItem: string;
};

const columnHelper = createColumnHelper<RoutingType>();

export const columns: ColumnDef<RoutingType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "Product",
        header: "Product",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Plant",
        header: "Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ProductionRoutingGroup",
        header: "Production Routing Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ProductionRouting",
        header: "Production Routing",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ProductionRoutingMatlAssgmt",
        header: "Production Routing Matl Assgmt",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        "enableGlobalFilter": false
    },
    {
        accessorKey: "ProductionRtgMatlAssgmtIntVers",
        header: "Production Rtg Matl Assgmt Int Vers",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CreationDate",
        header: "Creation Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "CreatedByUser",
        header: "Created By User",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "LastChangeDate",
        header: "Last Change Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "LastChangedByUser",
        header: "Last Changed By User",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValidityStartDate",
        header: "Validity Start Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "ChangeNumber",
        header: "Change Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesOrder",
        header: "Sales Order",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesOrderItem",
        header: "Sales Order Item",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }
];

