import React, {useState, useCallback} from "react";
import { sourceEndpointColumns } from "./types";
import CommonTable from "../../common/common-table/common-table";
import AddSourceEndpointModal from "./add-source-endpoint-modal";
import SelectionCheckbox from "../../common/common-table/selection-checkbox";

const LIST_VIEW = "LIST_VIEW";
const CREATE_VIEW = "CREATE_VIEW";
const EDIT_VIEW = "EDIT_VIEW";
const DELETE_VIEW = "DELETE_VIEW";


const endPoints = [
    {
        id: 1,
        name: "SAP Mara Service",
        user: "admin",
        password: "SapServer@1234",
        baseurl: "https://sap.hana.com",
       is_active: false
    },
    {
        id: 2,
        name: "SAP Mara Service 2",
        user: "admin 2",
        password: "SapServer@12345",
        baseurl: "https://sap.hana2.com",
       is_active: true
    },
    {
        id: 3,
        name: "SAP Mara Service 3",
        user: "admin 3",
        password: "SapServer@6",
        baseurl: "https://sap.hana3.com",
       is_active: true
    },
    {
        id: 4,
        name: "SAP Mara Service 3",
        user: "admin 3",
        password: "SapServer@6",
        baseurl: "https://sap.hana3.com",
       is_active: true
    }
];


const headerSelectCell = {
    id: 'select',
    header: ({ table }) => (
      <SelectionCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
        <SelectionCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
    ),
};

export default function SourceEndpoints() {

    const [showModal, setShowModal] = useState(false);

    const handleRefreshStatus = () => {
        console.log("Refresh triggered")
    }

    const handleAddEndpointClick = () => {

    }

    const onDeleteClick = () => {

    }

    const onModalCloseClick = useCallback(() => {
        setShowModal(false);
    },[])

    const onModalOpenClick = useCallback(() => {
        setShowModal(true);
    }, []);


    return(<div className="container-fluid">
        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", margin: "15px"}}>
            <strong>Data Acquisition / Source Endpoints</strong>
        </div>
        
        <CommonTable 
            data={[...endPoints]}
            columns={[headerSelectCell, ...sourceEndpointColumns]}
            pageSize={15}
            enableGlobalFilter
            enableRefresh
            onRefreshClick={handleRefreshStatus}
            enableAdd
            onAddClick={onModalOpenClick}
            enableDelete
        />
        <AddSourceEndpointModal onModalCloseClick={onModalCloseClick} showModal={showModal}/>
    </div>);
}