import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type CostCenterType = {
    ControllingArea:                string;
    CostCenter:                     number;
    ValidityEndDate:                null;
    ValidityStartDate:              Date;
    CostCenterName:                 string;
    CostCenterDescription:          string;
    IsBlkdForPrimaryCostsPosting:   null;
    IsBlockedForPlanPrimaryCosts:   null;
    CompanyCode:                    number;
    BusinessArea:                   null;
    CostCenterCategory:             string;
    CostCtrResponsiblePersonName:   string;
    CostCtrResponsibleUser:         null;
    CostCenterCurrency:             string;
    // CostingSheet:                   null;
    // TaxJurisdiction:                null;
    // ProfitCenter:                   string;
    // Plant:                          null;
    // LogicalSystem:                  null;
    // CostCenterCreationDate:         Date;
    // CostCenterCreatedByUser:        string;
    // IsBlkdForSecondaryCostsPosting: null;
    // IsBlockedForRevenuePosting:     string;
    // IsBlockedForCommitmentPosting:  string;
    // IsBlockedForPlanSecondaryCosts: null;
    // IsBlockedForPlanRevenues:       string;
    // CostCenterAllocationMethod:     null;
    // ConsumptionQtyIsRecorded:       string;
    // Department:                     null;
    // Country:                        null;
    // FormOfAddress:                  null;
    // AddressName:                    null;
    // AddressAdditionalName:          null;
    // CostCenterAddrName3:            null;
    // CostCenterAddrName4:            null;
    // CityName:                       null;
    // District:                       null;
    // StreetAddressName:              null;
    // POBox:                          null;
    // PostalCode:                     null;
    // POBoxPostalCode:                null;
    // Region:                         null;
    // Language:                       string;
    // TeleboxNumber:                  null;
    // PhoneNumber1:                   null;
    // PhoneNumber2:                   null;
    // FaxNumber:                      null;
    // TeletexNumber:                  null;
    // TelexNumber:                    null;
    // DataCommunicationPhoneNumber:   null;
    // CostCenterPrinterDestination:   null;
    // CostCenterStandardHierArea:     string;
    // CostCenterFunction:             null;
    // CostCenterAlternativeFunction:  null;
    // FunctionalArea:                 string;
    // ActyIndepFormulaPlanningTmpl:   null;
    // ActyDepdntFormulaPlanningTmpl:  null;
    // ActyIndependentAllocationTmpl:  null;
    // ActyDependentAllocationTmpl:    null;
    // ActlIndepStatisticalKeyFigures: null;
    // ActlDepStatisticalKeyFigures:   null;
    // JointVenture:                   null;
    // JointVentureRecoveryCode:       null;
    // JointVentureEquityType:         null;
    // JointVentureObjectType:         null;
    // JointVentureClass:              null;
    // JointVentureSubClass:           null;
    // BudgetCarryingCostCenter:       null;
    // AvailabilityControlProfile:     null;
    // AvailabilityControlIsActive:    null;
    // Fund:                           null;
    // GrantID:                        null;
    // FundIsFixAssigned:              null;
    // GrantIDIsFixAssigned:           null;
    // FunctionalAreaIsFixAssigned:    null;
    // CostCenterIsBudgetCarrying:     null;
}


const columnHelper = createColumnHelper<CostCenterType>();

export const columns: ColumnDef<CostCenterType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "ControllingArea",
        header: "Controlling Area",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CostCenter",
        header: "Cost Center",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "ValidityEndDate",
        header: "Validity End Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "ValidityStartDate",
        header: "Validity Start Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "CostCenterName",
        header: "Cost Center Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CostCenterDescription",
        header: "Cost Center Description",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsBlkdForPrimaryCostsPosting",
        header: "Is Blkd For Primary Costs Posting",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsBlockedForPlanPrimaryCosts",
        header: "Is Blocked For Plan Primary Costs",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CompanyCode",
        header: "Company Code",
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "BusinessArea",
        header: "Business Area",
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CostCenterCategory",
        header: "Cost Center Category",
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CostCtrResponsiblePersonName",
        header: "Cost Ctr Responsible Person Name",
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CostCtrResponsibleUser",
        header: "Cost Ctr Responsible User",
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CostCenterCurrency",
        header: "Cost Center Currency",
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    } 
];