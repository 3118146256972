import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type InspectionPlanType = {   
    InspectionPlanGroup:            string;
    InspectionPlan:                 string;
    Plant:                          string;
    BillOfOperationsUsage:          string;
    BillOfOperationsStatus:         string;
    ResponsiblePlannerGroup:        string;
    BillOfOperationsUnit:           string;
    ReplacedBillOfOperation:        string;
    InspPlanHasMultipleSpec:        string;
    InspSubsetFieldCombination:     string;
    InspectionPartialLotAssignment: string;
    SmplDrawingProcedure:           string;
    SmplDrawingProcedureVersion:    string;
    InspectionLotDynamicLevel:      string;
    InspLotDynamicRule:             string;
    InspExternalNumberingOfValues:  string;
    ChangeNumbering:                string;
    ValidityStartDate:              string;
}

const columnHelper = createColumnHelper<InspectionPlanType>();

export const columns: ColumnDef<InspectionPlanType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "InspectionPlanGroup",
        header: "Inspection Plan Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspectionPlan",
        header: "Inspection Plan",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Plant",
        header: "Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillOfOperationsUsage",
        header: "Bill Of Operations Usage",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillOfOperationsStatus",
        header: "Bill Of Operations Status",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ResponsiblePlannerGroup",
        header: "Responsible Planner Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillOfOperationsUnit",
        header: "Bill Of Operations Unit",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ReplacedBillOfOperation",
        header: "Replaced Bill Of Operation",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspPlanHasMultipleSpec",
        header: "Insp Plan Has Multiple Spec",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspSubsetFieldCombination",
        header: "Insp Subset Field Combination",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspectionPartialLotAssignment",
        header: "Inspection Partial Lot Assignment",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SmplDrawingProcedure",
        header: "Smpl Drawing Procedure",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SmplDrawingProcedureVersion",
        header: "Smpl Drawing Procedure Version",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspectionLotDynamicLevel",
        header: "Inspection Lot Dynamic Level",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspLotDynamicRule",
        header: "Insp Lot Dynamic Rule",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InspExternalNumberingOfValues",
        header: "Insp External Numbering Of Values",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ChangeNumbering",
        header: "Change Numbering",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValidityStartDate",
        header: "Validity Start Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    }
]
