import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../common/common-table/TableCell";
import EditCell from "../../common/common-table/EditCell";
import moment from "moment";
import LinkCell from "../../common/common-table/LinkCell";
import FixedWidthCell from "../../common/common-table/FixedWidthCell";

export type TableStatusPreviewType = {
    File_ID: number;
    table_name: string;
    download_status: string;
    no_of_records_in_source: string | null;
    no_of_records_downloaded: string | null;
    error: string | null;
    created_at: string;
    updated_at: string;
}

// const columnHelper = createColumnHelper<TableStatusPreviewType>();

export const columns: ColumnDef<TableStatusPreviewType>[] = [
    {
        accessorKey: "File_ID",
        header: "File Id",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "table_name",
        header: "Name",
        cell: LinkCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "download_status",
        header: "Download Status",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "no_of_records_in_source",
        header: "Records in Source",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "no_of_records_downloaded",
        header: "Records Downloaded",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "error",
        header: "Error",
        cell: FixedWidthCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "created_at",
        header: "Creation At",
        cell: info => moment(info.getValue() as string).format('lll') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "updated_at",
        header: "Updated At",
        cell: info => moment(info.getValue() as string).format('lll') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
];


export type EndpointType = {
    name: string;
    user: string;
    password: string;
    baseurl: string;
    is_active: boolean;
}

const columnHelper = createColumnHelper<EndpointType>();

export const sourceEndpointColumns: ColumnDef<EndpointType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "name",
        header: "Source Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "user",
        header: "User Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "password",
        header: "Password",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "baseurl",
        header: "Base Url",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "is_active",
        header: "Active",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
];

