import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import { createContext, useContext, useState } from "react";
import { API_URL } from "./utils/constants";
import { navs } from "./Navs";
import NotFound from "./components/notfound/NotFound";
import Header from "./components/Header";
import { filterNavs } from "./utils/helperFunctions";
import { isTokenValid } from "./common/helpers/isTokenValid";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

function App() {

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {
      uname: "",
      role: "",
      isAuthenticated: false,
    }
  );
  
  const login = (username, password) => {
    return new Promise(async (res, rej) => {
      try {
        let resp = await fetch(API_URL + "login/", {
          method: "POST",
          body: JSON.stringify({
            email: username,
            password: password,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        resp = await resp.json();
        if (resp.error) {
          rej(resp.error);
          return;
        }
        sessionStorage.setItem("access", resp.access);
        sessionStorage.setItem("refresh", resp.refresh);
        setUser({ uname: resp.email, role: resp.role});
        
        window.location.href = "/home"
        localStorage.setItem(
          "user",
          JSON.stringify({
            uname: resp.email,
            role: resp.role,
          })
        );
        res(resp);
        console.log(resp);
      } catch (err) {
        rej(err.message);
        sessionStorage.removeItem("access");
        sessionStorage.removeItem("refresh");
      }
    });
  };

  const logout = () => {
    setUser({ ...user, isAuthenticated: false });
    localStorage.removeItem("user");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Header />,
      errorElement: <NotFound />,
      children: filterNavs(navs, user),
    },
  ]);
  
  return (
    <div>
      {/* <Header /> */}
      <AuthContext.Provider value={{ user, login, logout }}>
        <RouterProvider router={router} />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
