import { Column, Row, Table, RowData } from "@tanstack/react-table";
import React, { useState, useEffect, ChangeEvent } from "react";

type Option = {
    label: string;
    value: string;
};

declare module '@tanstack/react-table' {
    interface TableMeta<TData extends RowData> {
      updateData: (rowIndex: number, columnId: string, value: unknown) => void;
      revertData: (rowIndex: number, revert: boolean) => void;
      editedRows: any;
      setEditedRows: (old: any) => unknown;
      onCellClick?: (rowData: TData) => void;
    }
}

declare module '@tanstack/react-table' {
    interface ColumnMeta<TData extends RowData, TValue> {
      type ?: string;
      options ?: Option[];
    }
}

interface TableCellProps<TData> {
    getValue: () => any;
    row: Row<TData>;
    column: Column<TData>;
    table: Table<TData>;
}

const TableCell = <TData,>({ getValue, row, column, table }: TableCellProps<TData>) => {
  const initialValue = getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    tableMeta?.updateData(row.index, column.id, e.target.value);
  };

  if (tableMeta?.editedRows[row.id]) {
    return columnMeta?.type === "select" ? (
      <select onChange={onSelectChange} value={initialValue}>
        {columnMeta?.options?.map((option: Option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        type={columnMeta?.type || "text"}
      />
    );
  }
  if(typeof value === 'boolean') return <span>{`${value}`}</span>
  return <span>{value}</span>;
};

export default TableCell;
