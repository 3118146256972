import { getAccessToken } from "../common/helpers/getAccessToken";
import { API_URL } from "./constants";

export const Call = async (url, method, payload) => {

  const token = await getAccessToken();
  const bearerToken = `Bearer ${token}`;

  let myheaders = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: bearerToken,
    },
  };
  if (method === "POST") {
    myheaders.body = payload;
  }
  myheaders.method = method;
  let res = await fetch(API_URL + url, myheaders);
  let data = await res.json();
  return data;
};
