import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type BpCustomerPartnerFunctionType = {
Customer:string;
SalesOrganization:string;
DistributionChannel:string;
Division:string;
PartnerCounter:string;
PartnerFunction:string;
BPCustomerNumber:string;
CustomerPartnerDescription:string;
DefaultPartner:string;
Supplier:string;
PersonnelNumber:string;
ContactPerson:string;
AddressID:string;
AuthorizationGroup:string;

}

const columnHelper = createColumnHelper<BpCustomerPartnerFunctionType>();


export const columns: ColumnDef<BpCustomerPartnerFunctionType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "Customer",
        header: "Customer",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SalesOrganization",
        header: "Sales Organization",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DistributionChannel",
        header: "Distribution Channel",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Division",
        header: "Division",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PartnerCounter",
        header: "Partner Counter",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PartnerFunction",
        header: "PartnerFunction",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BPCustomerNumber",
        header: "BP Customer Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CustomerPartnerDescription",
        header: "Customer Partner Description",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DefaultPartner",
        header: "Default Partner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Supplier",
        header: "Supplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PersonnelNumber",
        header: "Personnel Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ContactPerson",
        header: "ContactPerson",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AddressID",
        header: "Address ID",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AuthorizationGroup",
        header: "Authorization Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }

];