import React, {useCallback, useEffect, useState} from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import CsvUploaderModal from "./csv-uploader-modal";
import TableStatusOverview from "./table-status-overview";
import TableView from "./table-view";
import { FaArrowLeft } from "react-icons/fa";

export const DATA_ACQUISITION_STATUS_OVERVIEW = "DATA_ACQUISITION_STATUS_OVERVIEW";
export const DATA_ACQUISITION_SINGLE_TABLE_VIEW = "DATA_ACQUISITION_SINGLE_TABLE_VIEW";

const DataAcquisitionOverAllStatus = () => {

    const [showModal, setShowModal] = useState(false);
    const [view, setView] = useState(DATA_ACQUISITION_STATUS_OVERVIEW);
    const [selectedTable, setSelectedTable] = useState("")

    const onModalCloseClick = useCallback(() => {
        setShowModal(false);
    },[])

    const onModalOpenClick = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleBackButtonClick = useCallback(()=>{
        setView(DATA_ACQUISITION_STATUS_OVERVIEW);
        setSelectedTable("")
    }, []);

    const handleSingleTableOverview = useCallback((name)=>{
        setSelectedTable(name);
        setView(DATA_ACQUISITION_SINGLE_TABLE_VIEW);
    },[]);

    return (
        <div className="container-fluid">
            <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", margin: "15px"}}>
                {view !== DATA_ACQUISITION_STATUS_OVERVIEW && <button
                    onClick={handleBackButtonClick}
                    className="action-button border-none">
                    <FaArrowLeft /> Back 
                </button>}
                <strong>Data Acquisition</strong>
                {view === DATA_ACQUISITION_STATUS_OVERVIEW && <strong>/ Over All Status</strong> }
                {view === DATA_ACQUISITION_SINGLE_TABLE_VIEW && <strong>/ ${selectedTable}</strong> }
            </div>
            
            {view === DATA_ACQUISITION_STATUS_OVERVIEW && <TableStatusOverview openCSVFileUploadModal={onModalOpenClick} onTableRowSelection={handleSingleTableOverview}/>}
            {view === DATA_ACQUISITION_SINGLE_TABLE_VIEW && <TableView tableName={selectedTable} />}
            <CsvUploaderModal onModalCloseClick={onModalCloseClick} showModal={showModal}/>
        </div>
    );
};

export default DataAcquisitionOverAllStatus;