import {jwtDecode} from 'jwt-decode';

interface JwtPayload {
  exp: number; // expiration time (in seconds since epoch)
}

export function isTokenValid(): boolean {
  // Retrieve the token from sessionStorage
  const token = sessionStorage.getItem('access');
  
  // If the token doesn't exist, it's not valid.
  if (!token) {
    return false;
  }

  try {
    // Decode the token to get its payload
    const decoded: JwtPayload = jwtDecode(token);
    // Check if the token's expiration time is in the future.
    // Note: JWT exp is in seconds; multiply by 1000 to convert to milliseconds.
    return decoded.exp * 1000 > Date.now();
  } catch (error) {
    // If decoding fails, the token is considered invalid.
    return false;
  }
}