import React, { useState } from "react";
import CommonModal from "../../common/common-modal";
import axios from "axios";
import { getAccessToken } from "../../common/helpers/getAccessToken";

const CsvUploaderModal = ({showModal, onModalCloseClick}) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    clearUploadForm();
    onModalCloseClick()
  };
  const handleYes = () => {
    // perform yes action
    onModalCloseClick();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setError("");
    setSuccessMessage("");

    if (!file) {
      setSelectedFile(null);
      setIsValidFile(false);
      return;
    }

    if (!file.name.endsWith(".csv")) {
      setError("Only CSV files are allowed.");
      setSelectedFile(null);
      setIsValidFile(false);
    } else {
      setSelectedFile(file);
      setIsValidFile(true);
    }
  };
  
  const clearUploadForm = () => {
    setUploading(false);
    setError("");
    setSuccessMessage("");
    setSelectedFile(null);
    setIsValidFile(false);
  };

  const handleUpload = async () => {
    if (!selectedFile || !isValidFile) return;

    setUploading(true);
    setError("");
    setSuccessMessage("");

    try {
      const token = await getAccessToken();
      const bearerToken = `Bearer ${token}`;

      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await axios.post("https://verosdata.com/api/data-acquisition/csv-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: bearerToken, 
        },
      });
      const data = response.data;
      let message = data?.message;

      if(data?.invalid_values?.length > 0){
        message = message + "But following are invalid tables: " +data?.invalid_values.join(" ");
      }

      setSuccessMessage(`File "${selectedFile.name}" uploaded successfully! ${message}`);
    } catch (err) {
      console.error(err);
      setError("File upload failed. Please try again. "+err);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="">
      {showModal && (
        <CommonModal  
          title="Upload CSV" 
          closeBtnCallback={handleClose}
          yesBtnCallback={handleYes}
          yesBtnLabel="">
          <div className="d-flex flex-column">
              <label htmlFor="formFile" className="form-label">Upload file:</label>
              <div className="input-group mb-3">  
                <input className="form-control" type="file" id="formFile" accept=".csv" onChange={handleFileChange} />
                <button 
                  className="btn btn-outline-primary" 
                  type="button" 
                  id="button-addon2"
                  onClick={handleUpload}
                  disabled={!selectedFile || !isValidFile || uploading}>
                    Upload
                </button>
              </div>
              {uploading && 
                <div className="d-flex align-items-center">
                  <p className="m-0 pe-1">Uploading the file ...</p>
                  <div class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                </div>}
              {error && <p className="text-danger fw-bold">{error}</p>}
              {successMessage && <p className="text-success fw-bold">{successMessage}</p>}
          </div>
        </CommonModal>
      )}
    </div>
  );
};

export default CsvUploaderModal;
