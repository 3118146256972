import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";

export type SourceListType = {
    Material:                     string;
    Plant:                        string;
    Supplier:                     string;
    PurchaseOutlineAgreement:     string;
    PurchaseOutlineAgreementItem: string;
    SupplyingPlant:               string;
    SourceOfSupplyIsBlocked:      string;
    PurchasingOrganization:       string;
    MRPSourcingControl:           string;
    OrderQuantityUnit:            string;
};

const columnHelper = createColumnHelper<SourceListType>();

export const columns: ColumnDef<SourceListType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "Material",
        header: "Material",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Plant",
        header: "Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Supplier",
        header: "Supplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchaseOutlineAgreement",
        header: "Purchase Outline Agreement",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchaseOutlineAgreementItem",
        header: "Purchase Outline Agreement Item",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplyingPlant",
        header: "Supplying Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SourceOfSupplyIsBlocked",
        header: "Source Of Supply Is Blocked",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchasingOrganization",
        header: "Purchasing Organization",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "MRPSourcingControl",
        header: "MRP Sourcing Control",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrderQuantityUnit",
        header: "Order Quantity Unit",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
];