import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { getAccessToken } from "../common/helpers/getAccessToken";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/constants";

export default function Tenant() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    tenant_id: Yup.string().required("Required"),
    tenant_name: Yup.string().required("Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          tenant_id: "",
          tenant_name: "",
        }}
        validationSchema={Schema}
        onSubmit={async(values, { resetForm, setSubmitting }) => {

          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;

          fetch(API_URL + "admin1_insert/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
              resetForm();
              setSubmitting(false);
            })
            .catch(function (res) {
              resetForm();
              console.log(res);
              setSubmitting(false);
              setErr("Error Occurred");
            });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="main">
            <label>Tenant Id :</label>
            <Field name="tenant_id" />
            {errors.tenant_id && touched.tenant_id ? (
              <div className="error">{errors.tenant_id}</div>
            ) : null}
            <label> Tenant Name :</label>
            <Field name="tenant_name" />
            {errors.tenant_name && touched.tenant_name ? (
              <div className="error">{errors.tenant_name}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <button className="primary-button" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting...." : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
      <div className="card" onClick={() => navigate("/tenant-view")}>
        <p>Display Tenants </p>
      </div>
    </div>
  );
}
