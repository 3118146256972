import React, {useState, useCallback} from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from "@tanstack/react-table";
import "./server-side-pagination-table.css";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const FaSortIcon = FaSort as unknown as React.FC;
const FaSortUpIcon = FaSortUp as unknown as React.FC;
const FaSortDownIcon = FaSortDown as unknown as React.FC;

interface ServerPaginationTableProps<TData> {
  data: TData[];
  totalRecords?: number;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  onPaginationChange: (pageIndex: number, pageSize: number) => void;
  isLoading?: boolean;
  globalFilterValue: string;
  onGlobalFilterChange: (value: string) => void;
}

function ServerSidePaginationTable<TData>({
  data,
  totalRecords,
  pageCount,
  pageIndex,
  pageSize,
  onPaginationChange,
  isLoading = false,
  globalFilterValue,
  onGlobalFilterChange,

}: ServerPaginationTableProps<TData>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const handleGlobalFilterChange = (value: string) => {
    if (onGlobalFilterChange) {
      onGlobalFilterChange(value);
    }
  };

  const handleClearInput = useCallback(() => {
    if (onGlobalFilterChange) {
      onGlobalFilterChange('');
    }
  }, [onGlobalFilterChange]);

  // Auto generate columns based on data keys.
  const autoColumns: ColumnDef<TData, any>[] = React.useMemo(() => {
    if (data.length === 0) return [];
    const keys = Object.keys(data[0] || {}) as (keyof TData)[];
    return keys.map((key) => ({
      accessorKey: key as string,
      header: (key as string).charAt(0).toUpperCase() + (key as string).slice(1),
    }));
  }, [data]);

  const table = useReactTable({
    data,
    columns: autoColumns,
    manualPagination: true,
    manualSorting: true,
    pageCount,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // Pagination handlers
  const goToPage = (newPageIndex: number) => {
    if (newPageIndex < 0) newPageIndex = 0;
    if (newPageIndex >= pageCount) newPageIndex = pageCount;
    onPaginationChange(newPageIndex, pageSize);
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(e.target.value);
    onPaginationChange(pageIndex, newSize);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newPage = Number(e.target.value);
    if (isNaN(newPage)) return;
    goToPage(newPage);
  };

  return (
    <div className="server-table-container">
      <div className="server-table-actions-section">
        <div></div>
        <div className="server-table-actions-right">
            <input
                type="text"
                placeholder="Search..."
                value={globalFilterValue}
                onChange={(e) => handleGlobalFilterChange(e.target.value)}
                className="search-filter"
            />
             {globalFilterValue && (
              <button
                onClick={handleClearInput}
                className="clear-button"
              >
                &#10006;
              </button>
            )}
        </div>
      </div>
      <div className="server-table-container-overflow">
        <table className="server-table-content">
          <thead className="server-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                    <div className="basic-table-header-cell">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() === "asc" ? <FaSortUpIcon /> : header.column.getIsSorted() === "desc" ? <FaSortDownIcon /> : <FaSortIcon />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="server-table-body">
            {isLoading ? (
              <tr>
                <td colSpan={autoColumns.length} style={{ textAlign: "center" }}>
                  Loading...
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <div className="pagination-container">
          {totalRecords && <span> Total Records: <strong>{totalRecords}</strong></span>}
          <button onClick={() => goToPage(1)} disabled={pageIndex === 1}>
            First
          </button>
          <button onClick={() => goToPage(pageIndex - 1)} disabled={pageIndex === 1}>
            Previous
          </button>
          <span style={{display: "flex"}}>
            <div>Page</div>
            <strong>
              &nbsp;{pageIndex} of{' '}
              {pageCount}
            </strong>
          </span>
          <span>
            | Go To Page{" "}
            <input
              type="number"
              value={pageIndex}
              onChange={handlePageInputChange}
              min={1}
              max={pageCount}
            />{" "}
            of {pageCount}
          </span>
          <button onClick={() => goToPage(pageIndex + 1)} disabled={pageIndex >= pageCount}>
            Next
          </button>
          <button onClick={() => goToPage(pageCount)} disabled={pageIndex >= pageCount}>
            Last
          </button>
          <span>
            Records per page:{" "}
            <select value={pageSize} onChange={handlePageSizeChange}>
              {[5, 10, 15, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </span>
        </div>
      </div>
    </div>
  );
}

export default ServerSidePaginationTable;