import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import classes from "./customerconfig.module.css";
import { API_URL } from "../../../utils/constants";
import { getAccessToken } from "../../../common/helpers/getAccessToken";

export default function CustomerConfig() {
  
  const [types, setTypes] = useState([]);


  useEffect(() => {

    const fetchData = async() => {
      const token = await getAccessToken();
      const bearerToken = `Bearer ${token}`

      fetch(API_URL + "bp-account-group", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: bearerToken,
        },
        method: "GET",
      })
        .then(async (res) => {
          let data = await res.json();
          console.log(data);
          setTypes(data);
        })
        .catch(function (res) {
          console.log(res);
        });
    }
    fetchData();
  }, []);

  return (
    <div>
      {types.length > 0 ? (
        <Formik
          initialValues={{
            checked: types.filter((x) => x.EXTERNIND).map((x) => x.ECC_KTOKD),
          }}
          onSubmit={async(values, { setSubmitting }) => {
            let payload = [];
            values.checked.forEach((element) => {
              payload.push({ ECC_KTOKD: element, EXTERNIND: true });
            });
            types.forEach((ele) => {
              if (!values.checked.includes(ele.ECC_KTOKD)) {
                payload.push({
                  ECC_KTOKD: ele.ECC_KTOKD,
                  EXTERNIND: false,
                });
              }
            });
            const token = await getAccessToken();
            const bearerToken = `Bearer ${token}`

            fetch(API_URL + "bp-account-group-update", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: bearerToken,
              },
              method: "POST",
              body: JSON.stringify(payload),
            })
              .then(async (res) => {
                await res.json();
                setSubmitting(false);
              })
              .catch(function (res) {
                console.log(res);
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={`${classes["checkbox-grp"]} table-container`}>
                <table>
                  <thead>
                    <tr className={classes.tableHeaders}>
                      <th>Internal Number</th>
                      {Object.keys(types[0])
                        .filter((x) => x !== "EXTERNIND" && x !== "MANDT")
                        .map((item) => {
                          return <th key={item}>{item}</th>;
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {types.map((ele) => {
                      return (
                        <tr key={ele.id}>
                          <td>
                            <Field
                              type="checkbox"
                              name="checked"
                              value={ele.ECC_KTOKD}
                            />
                          </td>
                          {Object.values(ele)
                            .filter((x) => x !== ele["EXTERNIND"] && x !== ele["MANDT"])
                            .map((item) => {
                              return (
                                <td key={item}>
                                  {item}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <button
                type="submit"
                className={`primary-button ${classes["select-button"]}`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting...." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={classes.load}>Loading....</div>
      )}
    </div>
  );
}
