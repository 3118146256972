import React, { useState } from "react";
import { API_URL } from "../utils/constants";
import { getAccessToken } from "../common/helpers/getAccessToken";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function Config() {
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    api: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          api: "",
          username: "",
          password: "",
        }}
        validationSchema={Schema}
        onSubmit={async(values) => {
          // console.log(values);

          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;
          fetch(API_URL + "config/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              console.log(data);
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              // navigate("/product/view", {
              //   state: ["Product", data],
              // });
            })
            .catch(function (res) {
              console.log(res);
              setErr("Error occurred");
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>URL :</label>
            <Field name="api" />
            {errors.api && touched.api ? (
              <div className="error">{errors.api}</div>
            ) : null}
            <label>Username :</label>
            <Field name="username" />
            {errors.username && touched.username ? (
              <div className="error">{errors.username}</div>
            ) : null}
            <label>Password :</label>
            <Field name="password" type="password" />
            {errors.password && touched.password ? (
              <div className="error">{errors.password}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <button className="primary-button" type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
