import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type BPSupplierType = {
    Supplier:                       string;
    CompanyCode:                    string;
    PurchasingOrganization:         string;
    BusinessPartner:                string;
    AuthorizationGroup:             string;
    BusinessPartnerFullName:        string;
    BusinessPartnerName:            string;
    OrganizationBPName1:            string;
    CreatedByUser:                  string;
    Language:                       string;
    CreationDate:                   string;
    Industry:                       string;
    InternationalLocationNumber1:   string;
    InternationalLocationNumber2:   string;
    IsNaturalPerson:                string;
    OrganizationBPName2:            string;
    OrganizationBPName3:            string;
    OrganizationBPName4:            string;
    SearchTerm1:                    null | string;
    SearchTerm2:                    null | string;
    NameCountry:                    string;
    TradingPartner:                 string;
    IndustrySystemType:             string;
    IsStandardIndustry:             string;
    Businesspartner:                string;
    AddressID:                      string;
    AdditionalStreetPrefixName:     null | string;
    AdditionalStreetSuffixName:     null | string;
    AddressTimeZone:                null | string;
    CareOfName:                     null | string;
    CityCode:                       null | string;
    CityName:                       null | string;
    CompanyPostalCode:              null | string;
    Country:                        string | null;
    County:                         null | string;
    CountyCode:                     null | string;
    DeliveryServiceNumber:          null | string;
    DeliveryServiceTypeCode:        null | string;
    District:                       null | string;
    HouseNumber:                    null | string;
    HouseNumberSupplementText:      null | string;
    POBox:                          null | string;
    POBoxIsWithoutNumber:           null | string;
    POBoxLobbyName:                 null | string;
    POBoxPostalCode:                null | string;
    PostalCode:                     null | string;
    PrfrdCommMediumType:            null | string;
    Region:                         null | string;
    StreetName:                     null | string;
    StreetPrefixName:               null | string;
    StreetSuffixName:               null | string;
    TransportZone:                  null | string;
    TownshipCode:                   null | string;
    TownshipName:                   null | string;
    Person:                         null | string;
    OrdinalNumber:                  null | string;
    IsDefaultEmailAddress:          null | string;
    EmailAddress:                   null | string;
    IsDefaultFaxNumber:             string | null;
    FaxCountry:                     string | null;
    FaxNumber:                      null | string;
    FaxNumberExtension:             null | string;
    DestinationLocationCountry:     string | null;
    IsDefaultPhoneNumber:           null | string;
    PhoneNumber:                    null | string;
    PhoneNumberExtension:           null | string;
    PhoneNumberType:                null | string;
    BankCountryKey:                 string | null;
    BankNumber:                     null | string;
    BankControlKey:                 null | string;
    BankAccountHolderName:          null | string;
    BankAccountName:                null | string;
    ValidityStartDate:              null;
    ValidityEndDate:                null;
    BankAccount:                    null | string;
    BankKey:                        null | string;
    BankCountry:                    string | null;
    AlternativePayeeAccountNumber:  string;
    PaymentIsBlockedForSupplier:    string;
    PostingIsBlocked:               string;
    SupplierAccountGroup:           string;
    VATRegistration:                string;
    AccountingClerk:                string;
    PaymentMethodsList:             string;
    ReconciliationAccount:          string;
    LayoutSortingRule:              string;
    CashPlanningGroup:              string;
    IsToBeCheckedForDuplicates:     string;
    SupplierAccountNumber:          string;
    CalculationSchemaGroupCode:     string;
    DeletionIndicator:              string;
    EvaldReceiptSettlementIsActive: string;
    IncotermsClassification:        string;
    IncotermsTransferLocation:      string;
    IncotermsLocation1:             string;
    InvoiceIsGoodsReceiptBased:     string;
    InvoiceIsMMServiceEntryBased:   string;
    MinimumOrderAmount:             number;
    PaymentTerms:                   string;
    PlanningCycle:                  string;
    PricingDateControl:             string;
    ProductUnitGroup:               string;
    PurOrdAutoGenerationIsAllowed:  string;
    PurchaseOrderCurrency:          string;
    PurchasingGroup:                string;
    RoundingProfile:                string;
    ShippingCondition:              string;
    SuplrDiscountInKindIsGranted:   string;
    SuplrInvcRevalIsAllowed:        string;
    SuplrIsRlvtForSettlmtMgmt:      string;
    SuplrPurgOrgIsRlvtForPriceDetn: string;
    SupplierABCClassificationCode:  string;
    SupplierIsReturnsSupplier:      string;
    SupplierPhoneNumber:            string;
    SupplierRespSalesPersonName:    string;
    SupplierConfirmationControlKey: string;
    IsOrderAcknRqd:                 string;
}

const columnHelper = createColumnHelper<BPSupplierType>();

export const columns: ColumnDef<BPSupplierType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "Supplier",
        header: "Supplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CompanyCode",
        header: "Company Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchasingOrganization",
        header: "Purchasing Organization",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text",
    },
    {
        accessorKey: "BusinessPartner",
        header: "Business Partner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AuthorizationGroup",
        header: "Authorization Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BusinessPartnerFullName",
        header: "Business Partner Full Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BusinessPartnerName",
        header: "Business Partner Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName1",
        header: "Organization BP Name1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CreatedByUser",
        header: "Created By User",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Language",
        header: "Language",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CreationDate",
        header: "Creation Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "Industry",
        header: "Industry",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InternationalLocationNumber1",
        header: "International Location Number1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InternationalLocationNumber2",
        header: "International Location Number2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsNaturalPerson",
        header: "Is Natural Person",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName2",
        header: "Organization BP Name2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName3",
        header: "Organization BP Name3",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrganizationBPName4",
        header: "Organization BP Name4",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SearchTerm1",
        header: "Search Term1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SearchTerm2",
        header: "Search Term2",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "NameCountry",
        header: "Name Country",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "TradingPartner",
        header: "Trading Partner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IndustrySystemType",
        header: "Industry System Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsStandardIndustry",
        header: "Is Standard Industry",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Businesspartner",
        header: "Business Partner",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AddressID",
        header: "Address ID",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AdditionalStreetPrefixName",
        header: "Additional Street Prefix Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AdditionalStreetSuffixName",
        header: "Additional Street Suffix Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AddressTimeZone",
        header: "Address Time Zone",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CareOfName",
        header: "Care Of Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CityCode",
        header: "City Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CityName",
        header: "City Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CompanyPostalCode",
        header: "Company Postal Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Country",
        header: "Country",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "County",
        header: "County",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CountyCode",
        header: "County Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DeliveryServiceNumber",
        header: "Delivery Service Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DeliveryServiceTypeCode",
        header: "Delivery Service Type Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "District",
        header: "District",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "HouseNumber",
        header: "House Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "HouseNumberSupplementText",
        header: "House Number Supplement Text",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "POBox",
        header: "PO Box",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "POBoxIsWithoutNumber",
        header: "PO Box Is Without Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "POBoxLobbyName",
        header: "PO Box Lobby Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "POBoxPostalCode",
        header: "PO Box Postal Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PostalCode",
        header: "Postal Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PrfrdCommMediumType",
        header: "Prfrd Comm Medium Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Region",
        header: "Region",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "StreetName",
        header: "Street Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "StreetPrefixName",
        header: "Street Prefix Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "StreetSuffixName",
        header: "Street Suffix Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "TransportZone",
        header: "Transport Zone",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "TownshipCode",
        header: "Township Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "TownshipName",
        header: "Township Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Person",
        header: "Person",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "OrdinalNumber",
        header: "Ordinal Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsDefaultEmailAddress",
        header: "Is Default Email Address",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "EmailAddress",
        header: "Email Address",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsDefaultFaxNumber",
        header: "Is Default Fax Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "FaxCountry",
        header: "Fax Country",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "FaxNumber",
        header: "Fax Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "FaxNumberExtension",
        header: "Fax Number Extension",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DestinationLocationCountry",
        header: "Destination Location Country",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsDefaultPhoneNumber",
        header: "Is Default Phone Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PhoneNumber",
        header: "Phone Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PhoneNumberExtension",
        header: "Phone Number Extension",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PhoneNumberType",
        header: "Phone Number Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankCountryKey",
        header: "Bank Country Key",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankNumber",
        header: "Bank Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankControlKey",
        header: "Bank Control Key",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankAccountHolderName",
        header: "Bank Account Holder Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankAccountName",
        header: "Bank Account Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValidityStartDate",
        header: "Validity Start Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ValidityEndDate",
        header: "Validity End Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankAccount",
        header: "Bank Account",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankKey",
        header: "Bank Key",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BankCountry",
        header: "Bank Country",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AlternativePayeeAccountNumber",
        header: "Alternative Payee Account Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PaymentIsBlockedForSupplier",
        header: "Payment Is Blocked For Supplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PostingIsBlocked",
        header: "Posting Is Blocked",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierAccountGroup",
        header: "Supplier Account Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "VATRegistration",
        header: "VAT Registration",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "AccountingClerk",
        header: "Accounting Clerk",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PaymentMethodsList",
        header: "Payment Methods List",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ReconciliationAccount",
        header: "Reconciliation Account",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "LayoutSortingRule",
        header: "Layout Sorting Rule",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CashPlanningGroup",
        header: "Cash Planning Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsToBeCheckedForDuplicates",
        header: "Is To Be Checked For Duplicates",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierAccountNumber",
        header: "Supplier Account Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "CalculationSchemaGroupCode",
        header: "Calculation Schema Group Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DeletionIndicator",
        header: "Deletion Indicator",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "EvaldReceiptSettlementIsActive",
        header: "Evald Receipt Settlement Is Active",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IncotermsClassification",
        header: "Incoterms Classification",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IncotermsTransferLocation",
        header: "Incoterms Transfer Location",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IncotermsLocation1",
        header: "Incoterms Location1",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InvoiceIsGoodsReceiptBased",
        header: "Invoice Is Goods Receipt Based",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "InvoiceIsMMServiceEntryBased",
        header: "Invoice Is MM Service Entry Based",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "MinimumOrderAmount",
        header: "Minimum Order Amount",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "PaymentTerms",
        header: "Payment Terms",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
        type: "date"
        },
        filterFn: "includesString",
        sortingFn: "text"

    },
    {
        accessorKey: "PlanningCycle",
        header: "Planning Cycle",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PricingDateControl",
        header: "Pricing Date Control",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ProductUnitGroup",
        header: "Product Unit Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurOrdAutoGenerationIsAllowed",
        header: "Pur Ord Auto Generation Is Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchaseOrderCurrency",
        header: "Purchase Order Currency",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "PurchasingGroup",
        header: "Purchasing Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "RoundingProfile",
        header: "Rounding Profile",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ShippingCondition",
        header: "Shipping Condition",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SuplrDiscountInKindIsGranted",
        header: "Suplr Discount In Kind Is Granted",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SuplrInvcRevalIsAllowed",
        header: "Suplr Invc Reval Is Allowed",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SuplrIsRlvtForSettlmtMgmt",
        header: "Suplr Is Rlvt For Settlmt Mgmt",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SuplrPurgOrgIsRlvtForPriceDetn",
        header: "Suplr Purg Org Is Rlvt For Price Detn",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierABCClassificationCode",
        header: "Supplier ABC Classification Code",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierIsReturnsSupplier",
        header: "Supplier Is Returns Supplier",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierPhoneNumber",
        header: "Supplier Phone Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierRespSalesPersonName",
        header: "Supplier Resp Sales Person Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "SupplierConfirmationControlKey",
        header: "Supplier Confirmation Control Key",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsOrderAcknRqd",
        header: "Is Order Ackn Rqd",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }
];


