import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";

export type BOMType = {
    BillOfMaterial:               string;
    BillOfMaterialVariant:        string;
    EngineeringChangeDocument:    string;
    Material:                     string;
    Plant:                        string;
    EngineeringChangeDocForEdit:  string;
    BOMHeaderInternalChangeCount: string;
    BillOfMaterialAuthsnGrp:      string;
    BOMVersionStatus:             string;
    IsConfiguredMaterial:         string;
    BOMGroup:                     string;
    BOMHeaderText:                string;
    BOMAlternativeText:           string;
    BillOfMaterialStatus:         string;
    HeaderValidityStartDate:      number;
    HeaderValidityEndDate:        null;
    ChgToEngineeringChgDocument:  string;
    IsMarkedForDeletion:          string;
    IsALE:                        string;
    MatFromLotSizeQuantity:       number;
    MaterialToLotSizeQuantity:    number;
    BOMHeaderBaseUnit:            string;
    BOMHeaderQuantityInBaseUnit:  number;
    BOMIsToBeDeleted:             string;
    DocumentIsCreatedByCAD:       string;
    LaboratoryOrDesignOffice:     string;
    LastChangeDateTime:           number;
};

const columnHelper = createColumnHelper<BOMType>();

export const columns: ColumnDef<BOMType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "BillOfMaterial",
        header: "Bill Of Material",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillOfMaterialVariant",
        header: "Bill Of Material Variant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "EngineeringChangeDocument",
        header: "Engineering Change Document",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Material",
        header: "Material",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Plant",
        header: "Plant",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "EngineeringChangeDocForEdit",
        header: "Engineering Change Doc For Edit",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BOMHeaderInternalChangeCount",
        header: "BOM Header Internal Change Count",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillOfMaterialAuthsnGrp",
        header: "Bill Of Material Authsn Grp",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BOMVersionStatus",
        header: "BOM Version Status",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsConfiguredMaterial",
        header: "Is Configured Material",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BOMGroup",
        header: "BOM Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BOMHeaderText",
        header: "BOM Header Text",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BOMAlternativeText",
        header: "BOM Alternative Text",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BillOfMaterialStatus",
        header: "Bill Of Material Status",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "HeaderValidityStartDate",
        header: "Header Validity Start Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "HeaderValidityEndDate",
        header: "Header Validity End Date",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    },
    {
        accessorKey: "ChgToEngineeringChgDocument",
        header: "Chg To Engineering Chg Document",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsMarkedForDeletion",
        header: "Is Marked For Deletion",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "IsALE",
        header: "Is ALE",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "MatFromLotSizeQuantity",
        header: "Mat From Lot Size Quantity",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "MaterialToLotSizeQuantity",
        header: "Material To Lot Size Quantity",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "BOMHeaderBaseUnit",
        header: "BOM Header Base Unit",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "BOMHeaderQuantityInBaseUnit",
        header: "BOM Header Quantity In Base Unit",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "number"
        },
        filterFn: "equals",
        sortingFn: "basic"
    },
    {
        accessorKey: "BOMIsToBeDeleted",
        header: "BOM Is To Be Deleted",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DocumentIsCreatedByCAD",
        header: "Document Is Created By CAD",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "LaboratoryOrDesignOffice",
        header: "Laboratory Or Design Office",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "LastChangeDateTime",
        header: "Last Change Date Time",
        cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
        sortUndefined: "last",
        meta: {
            type: "date"
        },
        sortingFn: "datetime",
        enableGlobalFilter: false
    }
];


