import "./index.css";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Overlay from "./../overlay/Overlay";
import { API_URL, object_names } from "../../utils/constants";
import { getAccessToken } from "../../common/helpers/getAccessToken";

export default function LoadData() {
  const validImageExtensions = ["xlsx", "csv"];
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const Schema = Yup.object().shape({
    object_name: Yup.string().required("Object name is Required"),
    file: Yup.mixed()
      .required("File is Required")
      .test("is-valid-type", "Not a valid file type", (values) => {
        for (let value of values) {
          return (
            value &&
            validImageExtensions.includes(
              value.name.split(".").pop().toLowerCase()
            )
          );
        }
        return values.length > 0;
      }),
  });

  return (
    <div>
      <Formik
        initialValues={{
          object_name: "",
          file: "",
        }}
        validationSchema={Schema}
        onSubmit={async(values, { resetForm }) => {
          const formData = new FormData();
          for (let i = 0; i < values.file.length; i++) {
            formData.append(values.file[i].name, values.file[i]);
          }
          // formData.append("fileName", values.file[0].name);
          formData.append("object_name", values.object_name);
          setErr("");
          setLoading(true);

          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;

          fetch(API_URL + "excel_upload/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: formData,
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setErr("");
            })
            .catch(function (res) {
              setErr("Error occurred");
            })
            .finally(() => {
              setLoading(false);
              document.getElementById("file").value = "";
              resetForm();
            });
        }}
      >
        {({ setFieldValue, errors, touched }) => (
          <Form className="main">
            <label>Object Name :</label>
            <Field as="select" name="object_name">
              <option value="">select a value ....</option>
              {object_names.length > 0 ? (
                object_names.map((x) => {
                  return (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  );
                })
              ) : (
                <option style={{ fontWeight: "bold" }}>Loading ....</option>
              )}
            </Field>
            {errors.object_name && touched.object_name ? (
              <div className="error">{errors.object_name}</div>
            ) : null}
            <label>Upload File :</label>
            <Field
              type="file"
              id="file"
              name="file"
              multiple
              value={undefined}
              onChange={(e) => {
                if (e.target.files.length === 0) return;
                setFieldValue("file", e.target.files);
              }}
            />
            {errors.file && touched.file ? (
              <div className="error">{errors.file}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            <button className="primary-button" type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {success && (
        <Overlay closeMethod={() => setSuccess(false)} isLoader={false}>
          <div>
            <p>Loaded Successfully</p>
          </div>
          <div className="pop-btn">
            <button className="primary-button" onClick={() => setSuccess(false)}>Close</button>
          </div>
        </Overlay>
      )}
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
