import React, { useCallback, useEffect, useState } from "react";
import ServerSidePaginationTable from "../../common/server-side-pagination-table/server-side-pagination-table";
import useGet from "../../common/hooks/useGet";

const TABLE_API = "data-acquisition/table";

const TableView = ({tableName}) => {
    
    const [globalFilterValue, setGlobalFilter] = useState();
    const [tableResult, tableError, isTableLoading, makeGetTableCall]= useGet(TABLE_API);

    useEffect(()=>{
        makeGetTableCall({queryParams: {name: tableName, page: 1, limit: 15}})
    },[makeGetTableCall, tableName]);

    const handlePaginationChange = useCallback((pageIndex, pageSize) => {
        makeGetTableCall({queryParams: {name: tableName, page: pageIndex, limit: pageSize}})
    },[makeGetTableCall, tableName])

    const handleGlobalFilterChange = useCallback((key) => {
        setGlobalFilter(key)
    }, []);

    return (<div>
            {tableError && <p className="text-danger">{tableError}</p>}
            {!tableError && tableResult?.results && 
                <ServerSidePaginationTable 
                    data={tableResult?.results}
                    totalRecords={tableResult?.meta?.total_count}
                    pageCount={tableResult?.meta?.total_pages}
                    pageIndex={tableResult?.meta?.current_page}
                    pageSize={tableResult?.results?.length}
                    onPaginationChange={handlePaginationChange}
                    isLoading={isTableLoading}
                    globalFilterValue={globalFilterValue}
                    onGlobalFilterChange={handleGlobalFilterChange}
                />}
        </div>)
};

export default TableView;