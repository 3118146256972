import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "../overlay/Overlay";
import { API_URL } from "../../utils/constants";
import MultiSelect from "../multi-select/MultiSelect";
import { getAccessToken } from "../../common/helpers/getAccessToken";

export default function CustomerSalesSelection() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const options = ["1710", "201", "202", "3910", "1110"];


  const [err, setErr] = useState("");
  const Schema = Yup.object().shape({
    Customer: Yup.string().required("Required"),
    SalesOrganization: Yup.array()
      .required("Required")
      .min(1, "Atleast one value should be selected"),
    DistributionChannel: Yup.array()
      .required("Required")
      .min(1, "Atleast one value should be selected"),
    Division: Yup.array()
      .required("Required")
      .min(1, "Atleast one value should be selected"),
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Formik
        initialValues={{
          Customer: "",
          SalesOrganization: [],
          DistributionChannel: [],
          Division: [],
        }}
        validationSchema={Schema}
        onSubmit={async(values) => {
          // console.log(values);
          setLoading(true);

          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;

          fetch(API_URL + "product_view/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setLoading(false);
              navigate("/product/view", {
                state: ["product", data],
              });
            })
            .catch(function (res) {
              console.log(res);
              setErr(res);
              setLoading(false);
            });
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form className="main">
            <label>Customer :</label>
            <Field name="Customer" />
            {errors.Customer && touched.Customer ? (
              <div className="error">{errors.Customer}</div>
            ) : null}
            <label>Sales Organization :</label>
            <MultiSelect
              vals={options}
              fieldName="SalesOrganization"
              setValues={setFieldValue}
            />
            {errors.SalesOrganization && touched.SalesOrganization ? (
              <div className="error">{errors.SalesOrganization}</div>
            ) : null}
            <label>Distribution Channel :</label>
            <MultiSelect
              vals={options}
              fieldName="DistributionChannel"
              setValues={setFieldValue}
            />
            {errors.DistributionChannel && touched.DistributionChannel ? (
              <div className="error">{errors.DistributionChannel}</div>
            ) : null}
            <label>Division :</label>
            <MultiSelect
              vals={options}
              fieldName="Division"
              setValues={setFieldValue}
            />
            {errors.Division && touched.Division ? (
              <div className="error">{errors.Division}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            <button className="primary-button" type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
