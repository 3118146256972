import React, { useState, useCallback } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import "./basic-table.css";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const FaSortIcon = FaSort as unknown as React.FC;
const FaSortUpIcon = FaSortUp as unknown as React.FC;
const FaSortDownIcon = FaSortDown as unknown as React.FC;

interface BasicTableProps {
  data: any[];
  pagination?: boolean;
}

const BasicTable: React.FC<BasicTableProps> = ({ data, pagination = true }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  
  // Dynamically generate columns from data
  const columns: ColumnDef<any>[] = Object.keys(data[0] || {}).map((key) => ({
    accessorKey: key,
    header: key.charAt(0).toUpperCase() + key.slice(1),
  }));

  const handleClearInput = useCallback(() => {
    setGlobalFilter('');
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    state: { globalFilter },
    onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <div className="basic-table-container">
      <div className="basic-table-actions-section">
        <div></div>
        <div className="basic-table-actions-right">
            <input
                type="text"
                placeholder="Search..."
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="search-filter"
            />
             {globalFilter && (
              <button
                onClick={handleClearInput}
                className="clear-button"
              >
                &#10006;
              </button>
            )}
        </div>
      </div>

      {/* Table */}
      <div className={pagination ? "basic-table-container-overflow" : "basic-table-container-vertical-scroll"}>
        <table className="basic-table-content">
          <thead className="basic-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="basic-table-header-cell">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getIsSorted() === "asc" ? <FaSortUpIcon /> : header.column.getIsSorted() === "desc" ? <FaSortDownIcon /> : <FaSortIcon />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="basic-table-body">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {pagination && (
         <div className="pagination-controls">
            <div className="pagination-container">
            <button
                className=""
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
            >
                First
            </button>
            <button
                className=""
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
            >
                Previous
            </button>
            
            <span style={{display: "flex"}}>
                <div>Page</div>
                <strong>
                &nbsp;{table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
                </strong>
            </span>
            <span className="">
                | Go to page:
                <input
                type="number"
                min="1"
                max={table.getPageCount()}
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    table.setPageIndex(page)
                }}
                className="border p-1 rounded w-16"
                />
            </span>
            <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                table.setPageSize(Number(e.target.value))
                }}
            >
                {[15, 20, 25, 30].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
                ))}
            </select>
            <button
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
            >
                Next
            </button>
            <button
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
            >
                Last
            </button>
            </div>
       </div>
      )}
    </div>
  );
};

export default BasicTable;
