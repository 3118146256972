import { jwtDecode } from 'jwt-decode';

const ACCESS_TOKEN_KEY = "access";
const REFRESH_TOKEN_KEY = "refresh";

interface JwtPayload {
  exp: number;
}

/**
 * Checks if the token is expired.
 * @param token The JWT access token
 * @returns True if expired, false if still valid or undecodable
 */
function isTokenExpired(token: string): boolean {
  try {
    const decoded: JwtPayload = jwtDecode(token);
    const isExpired = decoded.exp * 1000 < Date.now();
    return isExpired;
  } catch (error) {
    // If it can't be decoded, it's not expired (handled by isTokenDecodable)
    return false;
  }
}

export function logout(): void {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
}

export async function getAccessToken(): Promise<string> {
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
  console.log("Access token:", accessToken);

  // No access token: logout
  if (!accessToken) {
    logout();
    return Promise.reject("Access token not found");
  }

  // Valid and not expired: return it
  if (!isTokenExpired(accessToken)) {
    return accessToken;
  }

  // Token is expired: attempt to refresh
  const refresh = sessionStorage.getItem(REFRESH_TOKEN_KEY);
  if (!refresh) {
    logout();
    return Promise.reject("Refresh token not found");
  }

  try {
    const response = await fetch('https://verosdata.com/api/data-acquisition/token/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ refresh })
    });

    if (!response.ok) {
      const errorText = await response.text();
      logout();
      return Promise.reject(`Failed to refresh token : ${errorText}`);
    }

    const data = await response.json();
    if (data && data.access) { // Adjust field name if needed (e.g., data.access)
      sessionStorage.setItem(ACCESS_TOKEN_KEY, data.access);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, data.refresh);
      return data.access;
    } else {
      logout();
      return Promise.reject("No access token in refresh response");
    }
  } catch (error) {
    logout();
    return Promise.reject(error);
  }
}