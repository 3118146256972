
import React, { MouseEventHandler, useCallback, useState } from "react";
import { ColumnDef, RowSelectionState, createColumnHelper } from "@tanstack/react-table";
import CommonTable from "../../common/common-table/common-table";
import { makeData } from "./makeData";
import { Person } from "./makeData";
import moment from "moment";
import SelectionCheckbox from "../../common/common-table/selection-checkbox";
import TableCell from "../../common/common-table/TableCell";
import EditCell from "../../common/common-table/EditCell";
import { generateTableConfig } from "./generateTableConfig";
import BasicTable from "../../common/basic-table/basic-table";
// import RCheckbox from "../../common/r-checkbox";
// import RInputTextBox from "../../common/r-input-text-box";


const columnHelper = createColumnHelper<Person>();

// Columns definition
const columns: ColumnDef<Person>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <SelectionCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
        <SelectionCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
    ),
  },
  columnHelper.display({
    id: "edit",
    cell: EditCell
  }),
  {
    
    accessorKey: "firstName",
    header: "First Name",
    cell: TableCell,
    sortUndefined: "last",
    filterFn: 'equalsString',
    sortingFn: 'text',
    meta: {
      type: "string"
    } 
  },
  {
    
    accessorKey: "lastName",
    header: "Last Name",
    sortUndefined: "last",
    cell: TableCell,
    filterFn: 'equalsString',
    sortingFn: 'text',
    meta: {
      type: "string"
    }  
  },
  {
    
    accessorKey: "age",
    header: "Age",
    sortUndefined: "last",
    cell: TableCell,
    filterFn: "equals",
    sortingFn: 'basic',
    meta: {
      type: "number",
    }  
  },
  {
    
    accessorKey: "visits",
    header: "Visits",
    sortUndefined: "last",
    cell: TableCell,
    filterFn: "equals",
    sortingFn: 'basic',
    meta: {
      type: "number",
    }   
  },
  {
    
    accessorKey: "progress",
    header: "Progress",
    sortUndefined: "last",
    cell: TableCell,
    filterFn: "equals",
    sortingFn: 'basic',
    meta: {
      type: "string",
    }   
  },
  {
    
    accessorKey: "rank",
    header: "Rank",
    sortUndefined: "last",
    cell: TableCell,
    filterFn: "equals",
    sortingFn: 'basic',
    meta: {
      type: "number",
    }   
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    sortUndefined: "last",
    cell: info => moment(info.getValue() as string).format('MM-DD-YYYY HH:MM:SS') ?? "N/A",
    meta: {
      type: "date"
    },
    enableGlobalFilter: false
    // cell: info => info.getValue(),
    // filterFn: "includesString",
  },
];

const Playground = () => {

  const [data, setData] = React.useState<Person[]>(() => makeData(1_000));
  const [inputValue, setInputValue] = useState("");

  console.log(data && data[1]);

  // // Callback for edit action
  // const handleEdit = (row) => {
  //   alert(`Editing row: ${JSON.stringify(row)}`);
  // };

  const handleEdit = useCallback((updatedRow:any) => {
    setData((prevData) =>
      prevData.map((row) => (row.id === updatedRow.id ? updatedRow : row))
    );
  },[]);

  // Callback for delete action
  const handleDelete = useCallback((selectedItems: RowSelectionState) => {
    const selectedIds = Object.keys(selectedItems);
    const selectedRows = data.filter(row => selectedIds.includes(row.id));
    console.log(selectedRows);
  },[data]);

  const handlePushToS4 = useCallback((selectedItems: RowSelectionState)=>{
    const selectedIds = Object.keys(selectedItems);
    const selectedRows = data.filter(row => selectedIds.includes(row.id));
    console.log(selectedRows);
  }, [data]);

  const handleViewTransactionLogs = useCallback(()=>{
    console.log("***Show Transaction Logs**")
  },[]);

  const handleCheckboxSelection = (e:React.ChangeEvent<HTMLInputElement >) => {
    console.log("handleCheckboxSelection", e.target.checked);
  }

  // // Callback for custom action
  // const handleCustomAction = (selectedRows) => {
  //   alert(`Custom action performed on rows: ${selectedRows.join(", ")}`);
  // };

  return (
    <div>
      {/* <h4>React Common Table Example</h4>
      <CommonTable
        columns={columns}
        data={data}
        pageSize={15}
        enablePagination
        enableRowEditing={true}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onPushToS4Click={handlePushToS4}
        enableDelete
        enablePushToS4
        enableViewTransactionLogs
        enableGlobalFilter
        onViewTransactionLogsClick={handleViewTransactionLogs}
      /> */}
      {/* <RCheckbox onCheck={handleCheckboxSelection} size="sm" label="Washington" />
      <RInputTextBox
        id="username"
        name="username"
        label="Username"
        value={inputValue}
        onChange={setInputValue}
        // errorMessage={inputValue.length < 3 ? "Must be at least 3 characters" : ""}
        size="lg"
      /> */}
      {/* {JSON.stringify(generateTableConfig())} */}
      {/* <TestingTable /> */}
      {/* <BasicTable data={results} pagination={true} /> */}
    </div>
  );
};

export default Playground;
