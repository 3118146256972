import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { getAccessToken } from "../common/helpers/getAccessToken";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/constants";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";

export default function Odata() {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const Schema = Yup.object().shape({
    ODATA: Yup.string().required("Required"),
    Object: Yup.string().required("Required"),
    API: Yup.string().required("Required"),
    // Filters: Yup.string().required("Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          ODATA: "",
          Object: "",
          API: "",
          // Filters: startDate,
        }}
        validationSchema={Schema}
        onSubmit={async(values, { resetForm }) => {
          values.Filters = startDate.toLocaleString(undefined, {
            timeZone: "Asia/Kolkata",
          });
          values.Filters = moment(values.Filters).format("YYYY-MM-DD");
          console.log(values);

          const token = await getAccessToken();
          const bearerToken = `Bearer ${token}`;

          fetch(API_URL + "odata/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: bearerToken,
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              resetForm();
              // navigate("/product/view", {
              //   state: ["Product", data],
              // });
            })
            .catch(function (res) {
              console.log(res);
              setErr("Error Occurred");
              resetForm();
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>ODATA :</label>
            <Field name="ODATA" />
            {errors.ODATA && touched.ODATA ? (
              <div className="error">{errors.ODATA}</div>
            ) : null}
            <label>Object :</label>
            <Field name="Object" />
            {errors.Object && touched.Object ? (
              <div className="error">{errors.Object}</div>
            ) : null}
            <label> Service URL :</label>
            <Field name="API" />
            {errors.API && touched.API ? (
              <div className="error">{errors.API}</div>
            ) : null}
            <label>Filter :</label>
            {/* <Field name="Filters" type="date" />
            {errors.Filters && touched.Filters ? (
              <div className="error">{errors.Filters}</div>
            ) : null} */}
            <DatePicker
              showIcon
              showTimeSelect
              minDate={new Date()}
              selected={startDate}
              dateFormat="MMMM, dd YYYY"
              onChange={(date) => setStartDate(date)}
              timeFormat="HH:mm"
              timeIntervals={5}
            />
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <button className="primary-button" type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      <div className="card" onClick={() => navigate("/odataView")}>
        <p>Display Data Lake </p>
      </div>
    </div>
  );
}
