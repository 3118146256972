import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import TableCell from "../../../common/common-table/TableCell";
import EditCell from "../../../common/common-table/EditCell";
import moment from "moment";
import { RoutingType } from "../routing/types";

export type ClassificationType = {
    ClassInternalID:string;
    ClassType:string;
    ClassTypeName:string;
    Class:string;
    ClassStatus:string;
    ClassStatusName:string;
    ClassGroup:string;
    ClassGroupName:string;
    ClassSearchAuthGrp:string;
    ClassClassfctnAuthGrp:string;
    ClassMaintAuthGrp:string;
    DocNumber:string;
    DocumentType:string;
    DocumentPart:string;
    DocumentVersion:string;
    SameClassfctnReaction:string;
    ClassStandardOrgName:string;
    ClassStandardNumber:string;
    ClassStandardStartDate:string;
    ClassStandardVersionStartDate:string;
    ClassStandardVersion:string;
    ClassStandardCharcTable:string;
    CreationDate:string;
    LastChangeDate:string;
    ClassIsLocal:string;
    ValidityStartDate:string;
    ValidityEndDate:string;
    ClassLastChangedDateTime:string;
    KeyDate:null;
    
}
const columnHelper = createColumnHelper<ClassificationType>();

export const columns: ColumnDef<ClassificationType>[] = [
    columnHelper.display({
        id: "edit",
        cell: EditCell
    }),
    {
        accessorKey: "ClassInternalID",
        header: "Class Internal ID",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ClassType",
        header: "Class Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ClassTypeName",
        header: "Class Type Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "Class",
        header: "Class",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ClassStatus",
        header: "Class Status",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ClassStatusName",
        header: "Class Status Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "ClassGroup",
        header: "Class Group",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassGroupName",
        header: "Class Group Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassSearchAuthGrp",
        header: "Class Search Auth Grp",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassClassfctnAuthGrp",
        header: "Class Classfctn Auth Grp",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassMaintAuthGrp",
        header: "Class Maint Auth Grp",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocNumber",
        header: "Doc Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },
    {
        accessorKey: "DocumentType",
        header: "Document Type",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocumentPart",
        header: "Document Part",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "DocumentVersion",
        header: "Document Version",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "SameClassfctnReaction",
        header: "Same Classfctn Reaction",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassStandardOrgName",
        header: "Class Standard Org Name",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassStandardNumber",
        header: "Class Standard Number",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassStandardStartDate",
        header: "Class Standard Start Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassStandardVersionStartDate",
        header: "Class Standard Version Start Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassStandardVersion",
        header: "Class Standard Version",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassStandardCharcTable",
        header: "Class Standard Charc Table",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "CreationDate",
        header: "Creation Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "LastChangeDate",
        header: "Last Change Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassIsLocal",
        header: "Class Is Local",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValidityStartDate",
        header: "Validity Start Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ValidityEndDate",
        header: "Validity End Date",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    },{
        accessorKey: "ClassLastChangedDateTime",
        header: "Class Last Changed Date Time",
        cell: TableCell,
        sortUndefined: "last",
        meta: {
            type: "string"
        },
        filterFn: "includesString",
        sortingFn: "text"
    }
];