import { Row, Table, RowData } from "@tanstack/react-table";
import Popover from "../popover";

interface TableCellProps<TData extends RowData> {
    getValue: () => any;
    row: Row<TData>;
    table: Table<TData>;
}

const FixedWidthCell = <TData,>({ getValue, row, table }: TableCellProps<TData>) => {
    const value = getValue();
  
    return (
      <div
        style={{ width: "200px" }}
      >
        {value && value.length > 20 ? <Popover 
            placement="bottom"
            trigger={<span>{value.substring(0, 28)+"..."}</span>}
            content={<div>{value}</div>}
        /> : value}
      </div>
    );
};

export default FixedWidthCell;